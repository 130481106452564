<template>
  <f7-popup
    ref="immersivePopup"
    :opened="opened"
    tablet-fullscreen
  >
    <f7-page ref="immersivePopupPage" bg-color="white">
      <f7-segmented class="scroll-arrows">
        <f7-button
          icon-f7="chevron_up_square_fill"
          @click="scrollToTop()"
        />
        <f7-button
          icon-f7="chevron_down_square_fill"
          @click="scrollToBottom()"
        />
      </f7-segmented>
      <f7-navbar>
        <f7-nav-left>
          <i class="f7-icons">eye</i> <span class="mode-title">Mode immersif</span>
        </f7-nav-left>
        <f7-nav-title>{{ title }}</f7-nav-title>
        <f7-nav-right>
          <f7-input
            type="select"
            :value="locale"
            @change="$emit('switch-language', $event)"
            @focus="showFullLanguageLabel = true"
            @blur="showFullLanguageLabel = false"
          >
            <option
              v-for="lang in availableLanguages"
              :key="'lang_'+lang.locale"
              :value="lang.locale"
            >
              {{ dynamicLanguageLabel(lang) }}
            </option>
          </f7-input>
          <f7-link
            tooltip="Sortir du mode immersif"
            class="no-margin-horizontal no-padding-horizontal button color-white"
            @click="close"
          >
            <i class="f7-icons">multiply_circle</i>
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-block
        class="no-breadcrumb"
        style="height: 100%"
      >
        <f7-row
          class="immersive-navigation"
          :class="{reduced: reduceNavigation}"
        >
          <f7-col
            width="15"
            class="immersive-previous"
            @click="$emit('previous')"
          >
            <f7-icon
              class="arrows"
              ios="f7:chevron_left"
            />
          </f7-col>
          <f7-col
            v-if="!reduceNavigation"
            :width="reduceNavigation ? 0 : 20"
            class="navigation-content-before"
          >
            <p
              v-if="previousModule"
              class="navigation-module navigation-previous"
              @click="$emit('previous-module')"
            >
              <span>{{ $t(previousModule.name) }}</span>
            </p>
            <p
              v-else
              class="navigation-module"
            >
              <span>&nbsp;</span>
            </p>
            <p
              v-if="previousEntry"
              class="navigation-entry navigation-previous"
              @click="$emit('previous-entry')"
            >
              <span>{{ $t(previousEntry.name) }}</span>
            </p>
            <p
              v-else
              class="navigation-entry"
            >
              <span>&nbsp;</span>
            </p>
            <p
              v-if="previousSubEntry"
              class="navigation-sub-entry navigation-previous"
              @click="$emit('previous-sub-entry')"
            >
              <span>{{ $t(previousSubEntry.name) }}</span>
            </p>
            <p
              v-else
              class="navigation-sub-entry"
            >
              <span>&nbsp;</span>
            </p>
          </f7-col>
          <f7-col
            :width="reduceNavigation ? 70 : 30"
            class="navigation-content"
          >
            <p
              v-if="currentModule"
              class="navigation-module"
              :class="{current: !currentEntry}"
              @click="currentEntry ? $emit('module-section'): null"
            >
              <span>{{ $t(currentModule.name) }}</span>
            </p>
            <i
              v-if="currentEntry"
              class="f7-icons"
            >chevron_down</i>
            <i v-else class="f7-icons">ellipsis_vertical</i>
            <p
              v-if="currentEntry"
              class="navigation-entry"
              :class="{current: !currentSubEntry}"
              @click="currentSubEntry ? $emit('entry-section'): null"
            >
              <span>{{ $t(currentEntry.name) }}</span>
            </p>
            <i
              v-if="currentSubEntry"
              class="f7-icons"
            >chevron_down</i>
            <i v-else-if="hasSubEntries" class="f7-icons">ellipsis_vertical</i>
            <p
              v-if="currentSubEntry"
              class="navigation-sub-entry"
            >
              <span>{{ $t(currentSubEntry.name) }}</span>
            </p>
          </f7-col>
          <f7-col
            v-if="!reduceNavigation"
            :width="reduceNavigation ? 0 : 20"
            class="navigation-content-after"
          >
            <p
              v-if="nextModule"
              class="navigation-module navigation-next"
              @click="$emit('next-module')"
            >
              <span>{{ $t(nextModule.name) }}</span>
            </p>
            <p
              v-else
              class="navigation-module"
            >
              <span>&nbsp;</span>
            </p>
            <p
              v-if="nextEntry"
              class="navigation-entry navigation-next"
              @click="$emit('next-entry')"
            >
              <span>{{ $t(nextEntry.name) }}</span>
            </p>
            <p
              v-else
              class="navigation-entry"
            >
              <span>&nbsp;</span>
            </p>
            <p
              v-if="nextSubEntry"
              class="navigation-sub-entry navigation-next"
              @click="$emit('next-sub-entry')"
            >
              <span>{{ $t(nextSubEntry.name) }}</span>
            </p>
            <p
              v-else
              class="navigation-sub-entry"
            >
              <span>&nbsp;</span>
            </p>
          </f7-col>
          <f7-col
            width="15"
            class="immersive-next"
            @click="$emit('next')"
          >
            <f7-icon
              class="arrows"
              ios="f7:chevron_right"
            />
          </f7-col>
        </f7-row>

        <input
          ref="photoInput"
          class="file-input"
          type="file"
          accept="image/*"
          multiple
          @change="$emit('add-photo')"
        >
        <f7-row
          class="immersive-hide-content-scroll"
          :class="'cols_'+nbCols"
        />

        <f7-row
          ref="mainView"
          class="extendable immersive-content"
          :class="'cols_'+nbCols"
        >
          <f7-col
            v-if="(currentSubEntry || currentEntry) && !isSectionHome"
            class="rate-section"
          >
            <edl-edit-immersive-actions
              :can-delete="canDelete"
              :can-rename="canRename"
              @delete="deleteElement"
              @rename="renameElement"
            />
            <edl-edit-rate
              immersive
              :entry="currentSubEntry ? currentSubEntry : currentEntry"
              :rate-info="currentEntry.rateInfo"
              :common-rate-info="info.commonRateInfo"
              :parent="currentSubEntry ? currentEntry : null"
              :context-gallery="contextGallery"
              :photo-context="currentPhotoContext"
              :manual-costing="isManualCosting"
              :auto-fill-observations="currentAutoFillObservations"
              :has-convention="hasConvention"
              :calibration="calibration"
              :calibration-config="calibrationConfig"
              :adding-photos="addingPhotos"
              :readonly="readonly"
              @update="$emit('update', $event)"
              @add-photo="$emit('add-photo', $event)"
              @add-rate-info="$emit('add-rate-info', $event)"
            />
          </f7-col>
          <f7-col v-else>
            <edl-edit-immersive-section
              :current="current"
              :module="currentModule"
              :entry="currentEntry"
              :sub-entry="currentSubEntry"
              :is-photos="currentEntryIsPhotos"
              :gallery="gallery"
              :adding-photos="addingPhotos"
              :opening-file-selector="openingFileSelector"
              :cant-rename="cantRename"
              :cant-delete="cantDelete"
              @select="select"
              @rename="renameElement"
              @delete="deleteElement"
              @unopened="unopened"
              @delete-all="$emit('delete-all-photos-from-entry')"
              @open-photo-input="$emit('open-photo-input')"
              @add-custom-sub-entry="addCustomSubEntry"
            />
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import EdlEditRate from "@/components/pages/Edl/EdlEdit/EdlEditRate/EdlEditRate.vue";
import language from "@/components/mixins/language";
import EdlEditImmersiveSection from "@/components/pages/Edl/EdlEdit/EdlEditImmersive/EdlEditImmersiveSection.vue";
import EdlEditImmersiveActions from "@/components/pages/Edl/EdlEdit/EdlEditImmersive/EdlEditImmersiveActions.vue";

export default {
  name: "EdlEditImmersive",
  components: {EdlEditImmersiveActions, EdlEditImmersiveSection, EdlEditRate},
  mixins: [language],
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: "EDL"
    },
    current: {
      type: Object,
      default: null
    },
    nbCols: {
      type: Number,
      default: 3
    },
    currentModule: {
      type: Object,
      default: null
    },
    previousModule: {
      type: Object,
      default: null
    },
    nextModule: {
      type: Object,
      default: null
    },
    currentEntry: {
      type: Object,
      default: null
    },
    previousEntry: {
      type: Object,
      default: null
    },
    nextEntry: {
      type: Object,
      default: null
    },
    currentSubEntry: {
      type: Object,
      default: null
    },
    previousSubEntry: {
      type: Object,
      default: null
    },
    nextSubEntry: {
      type: Object,
      default: null
    },
    currentEntryIsPhotos: {
      type: Boolean,
      default: false
    },
    gallery: {
      type: Array,
      default: null
    },
    contextGallery: {
      type: Array,
      default: null
    },
    currentPhotoContext: {
      type: String,
      default: null
    },
    openingFileSelector: {
      type: Boolean,
      default: false
    },
    currentAutoFillObservations: {
      type: Array,
      default: null
    },
    isModuleSelected: {
      type: Boolean,
      default: false
    },
    isEntrySelected: {
      type: Boolean,
      default: false
    },
    isSubEntrySelected: {
      type: Boolean,
      default: false
    },
    hasSubEntries: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    hasConvention: {
      type: Boolean,
      default: false
    },
    calibration: {
      type: Object,
      default: null
    },
    calibrationConfig: {
      type: Object,
      default: null
    },
    addingPhotos: {
      type: Number,
      default: 0
    },
    cantDelete: {
      type: Array,
      default: () => []
    },
    cantRename: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'close',
    'init',
    'next',
    'previous',
    'update',
    'add-photo',
    'add-rate-info',
    'switch-language',
    'previous-module',
    'next-module',
    'previous-entry',
    'next-entry',
    'previous-sub-entry',
    'next-sub-entry',
    'module-section',
    'entry-section',
    'delete-module',
    'delete-entry',
    'delete-sub-entry',
    'delete-photo',
    'delete-all-photos-from-entry',
    'rename-module',
    'rename-entry',
    'rename-sub-entry',
    'select',
    'open-photo-input', 'add-custom-sub-entry', 'unopened'
  ],
  data: function () {
    return {
      reduceNavigation: false
    };
  },
  computed: {
    notInitiated: function () {
      return this.current === null || (this.current.entry === null && this.current.subEntry === null);
    },
    isManualCosting: function () {
      if(this.notInitiated) {
        return false;
      }
      return this.info.conventionSortieManuelle !== undefined && this.info.conventionSortieManuelle;
    },
    canDelete: function () {
      if(this.notInitiated) {
        return false;
      }
      return this.isSubEntrySelected ? !this.cantDelete.includes(this.currentSubEntry.name) : !this.cantDelete.includes(this.currentEntry.name);
    },
    canRename: function () {
      if(this.notInitiated) {
        return false;
      }
      return this.isSubEntrySelected ? !this.cantRename.includes(this.currentSubEntry.name) : !this.cantRename.includes(this.currentEntry.name);
    },
    pageContentEl: function () {
      const page = this.$refs.immersivePopupPage.$el;
      return page.querySelector('.page-content');
    },
    isSectionHome: function () {
      return (this.currentModule && !this.currentEntry) || (this.currentEntry && this.hasSubEntries && !this.currentSubEntry);
    }
  },
  watch: {
    opened: function (isOpen) {
      if(isOpen && this.notInitiated) {
        this.$emit('init');
      } else if(isOpen && this.currentEntry.name === 'Photos' && this.isSubEntrySelected) {
        this.$emit('entry-section');
      }
    },
    current: {
      handler: function () {
        this.scrollToTop();
      },
      deep: true
    }
  },
  mounted: function () {
    const popup = this.$refs.immersivePopup.$el;

    popup.addEventListener("scroll", (event) => {
      this.reduceNavigation = event.target.scrollTop > 10;
    }, {capture: true});
  },
  methods: {
    deleteElement(isList = false, index = null) {
      if(isList) {
        if(this.isEntrySelected) {
          this.$emit('delete-sub-entry', index, false);
        } else {
          this.$emit('delete-entry', index, false);
        }
      } else {
        if(this.isSubEntrySelected) {
          this.$emit('delete-sub-entry', this.current.subEntry, true);
        } else if (this.isEntrySelected) {
          this.$emit('delete-entry', this.current.entry, true);
        } else if (this.isModuleSelected) {
          this.$emit('delete-module', this.current.module, true);
        }
      }
    },
    renameElement(isList = false, index = null) {
      if(isList) {
        if(this.isEntrySelected) {
          this.$emit('rename-sub-entry', index, false);
        } else {
          this.$emit('rename-entry', index, false);
        }
      } else {
        if(this.isSubEntrySelected) {
          this.$emit('rename-sub-entry', this.current.subEntry, false);
        } else if (this.isEntrySelected) {
          this.$emit('rename-entry', this.current.entry, false);
        } else if (this.isModuleSelected) {
          this.$emit('rename-module', this.current.module, false);
        }
      }
    },
    unopened(type, index) {
      this.$emit('unopened', type, index);
    },
    addCustomSubEntry() {
      this.$emit('add-custom-sub-entry');
    },
    select(type, index) {
      this.$emit('select', type, index);
    },
    close() {
      this.$emit('close');
    },
    scrollToTop: function () {
      this.pageContentEl.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    scrollToBottom() {
      this.pageContentEl.scrollTo({
        top: this.pageContentEl.scrollHeight,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.mode-title {
  margin-left: 10px;
}
.scroll-arrows {
  position: fixed;
  right: 10px;
  bottom: 20px;
  z-index: 1000;
  display: block;
  .button {
    height: 100%;
    :deep(i) {
      color: rgba(var(--f7-theme-color-grey-rgb), 0.8);
      font-size: 48px;
    }
  }
}
.immersive-navigation {
  z-index: 100;
  position: fixed;
  width: 98%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  background-color: var(--f7-theme-color-light-grey);
  margin-top: 10px;
  display:flex;
  align-items: stretch;
  flex-wrap: nowrap;
  .immersive-previous, .immersive-next {
    background-color: var(--f7-theme-color-light-grey);
    display: flex;
    flex-direction: column;
    flex: 0 0 5rem;
    align-items: center;
    justify-content: center;
    i {
      font-size: 50px;
      font-weight: bold;
      color: var(--f7-theme-color);
    }
  }
  .immersive-previous {
    border-radius: 10px 0 0 10px;
  }
  .immersive-next {
    border-radius: 0 10px 10px 0;
  }
  .navigation-content {
    padding: 20px 0 20px 0;
    display: block;
    text-align: center;
    i {
      margin-top: 2px;
      margin-left: 3px;
      margin-right: 3px;
      font-size: 18px;
    }
    p {
      margin-top: 6px;
      margin-bottom: 4px;
      transition: font-size 0.8s, margin 0.8s, padding 0.8s;
      color: var(--f7-theme-color-very-dark-grey);
      &.navigation-module, &.navigation-comment {
        font-size: 22px;
      }
      &.navigation-entry {
        font-size: 24px;
      }
      &.navigation-module.current, &.navigation-entry.current, &.navigation-sub-entry {
        font-weight: bold;
        font-size: 26px;
      }
    }
  }
  .navigation-content-before, .navigation-content-after {
    padding: 20px 0 20px 0;
    display: block;
    p {
      cursor: pointer;
      margin-top: 6px;
      margin-bottom: 4px;
      transition: font-size 0.8s, margin 0.8s, padding 0.8s;
      color: var(--f7-theme-color-dark-grey);
      &:hover {
        color: var(--f7-theme-color);
      }
      position: relative;
      font-size: 14px;
      &.navigation-module, &.navigation-comment {
        top: 6px;
      }
      &.navigation-entry {
        top: 46px;
      }
      &.navigation-sub-entry {
        top: 90px;
      }
    }
  }
  .navigation-content-before {
    padding-right: 15px;
    border-right: 1px dotted white;
    text-align: right;
    .navigation-previous span::before {
      content: "←";
      margin-left: 5px;
      float: right;
    }
  }
  .navigation-content-after {
    padding-left: 15px;
    border-left: 1px dotted white;
    text-align: left;
    .navigation-next span::before {
      content: "→";
      margin-right: 5px;
    }
  }
  .navigation-previous, .navigation-next {
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
  &.reduced {
    .navigation-content {
      padding: 5px 0 5px 0;
      p {
        margin-top: 0;
        margin-bottom: 3px;
        &.navigation-module, &.navigation-comment {
          font-size: 14px;
        }
        &.navigation-entry {
          font-size: 16px;
        }
        &.navigation-sub-entry {
          font-size: 18px;
        }
      }
    }
  }
}

.immersive-content {
  &.cols_2 {
    margin-top: 130px;
  }
  &.cols_3 {
    margin-top: 170px;
  }
  &.cols_4 {
    margin-top: 230px;
  }
}

.immersive-hide-content-scroll {
  background-color: white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100px;
}

@media screen and (max-width: 1024px) {
  .immersive-navigation {
    .navigation-content {
      p {
        &.navigation-module, &.navigation-comment {
          font-size: 18px;
        }

        &.navigation-entry {
          font-size: 22px;
        }

        &.navigation-entry.current, &.navigation-sub-entry {
          font-size: 24px;
        }
      }
    }
  }
  .immersive-navigation {
    .navigation-content-before, .navigation-content-after {
      p {
        &.navigation-module, &.navigation-comment {
          top: 4px;
        }

        &.navigation-entry {
          top: 38px;
        }

        &.navigation-sub-entry {
          top: 80px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .immersive-content {
    &.cols_3 {
      margin-top: 100px;
    }
    &.cols_4 {
      margin-top: 150px;
    }
  }
  .immersive-navigation {
    .navigation-content {
      padding: 5px 0 5px 0;
      --f7-cols-per-row: 1.42857143;
      p {
        margin-top: 0;
        margin-bottom: 3px;

        &.navigation-module, &.navigation-comment {
          font-size: 14px;
        }

        &.navigation-entry {
          font-size: 16px;
        }

        &.navigation-entry.current, &.navigation-sub-entry {
          font-size: 18px;
        }
      }
    }
    .navigation-content-before, .navigation-content-after {
      display: none;
    }
  }
}
</style>
