<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      :back="true"
    >
      <template #back-actions>
        <a @click="backToLang">
          <i class="f7-icons small-icon">chevron_left</i> <span>Langue / Language</span>
        </a>
      </template>
      <template #global-actions>
        <f7-link
          class="no-margin-horizontal button color-white"
          @click="logout"
        >
          <i class="f7-icons">power</i>
        </f7-link>
      </template>
    </navigation-top>

    <f7-page-content>
      <f7-row
        v-if="!garant"
        class="margin-top"
      >
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
        >
          <f7-button
            type="button"
            @click="addGarant"
          >
            <i class="f7-icons">person_2_fill</i> <span>{{ $t("Définir un représentant") }}</span>
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
      <transition name="garant">
        <f7-row v-if="garant">
          <f7-col>
            <f7-block-title>
              <i class="f7-icons">person_2_fill</i> <span>{{ $t("Représentant") }}</span>
              <a
                class="remove-garant"
                @click="removeGarant"
              ><i class="f7-icons">multiply_circle_fill</i></a>
            </f7-block-title>
            <f7-list>
              <ul>
                <edl-info-input
                  label="Nom du représentant"
                  path="garant.name"
                  :info="info"
                  @set="updateInfo"
                />
              </ul>
            </f7-list>
          </f7-col>
        </f7-row>
      </transition>

      <transition-group
        name="renter"
        tag="div"
      >
        <f7-row
          v-for="(renter, index) in renters"
          :key="'renter'+index"
        >
          <f7-col>
            <f7-row>
              <f7-col
                :medium="renterMediumCols"
                width="100"
                class="no-margin-bottom-medium"
              >
                <f7-block-title>
                  <i class="f7-icons">person_crop_circle</i> <span>{{ $t(`Locataire ${edlIO}`) }}</span> {{ (index > 1) ? '#'+index : '' }}
                  <a
                    v-show="index > 1 && +index === nbRenters"
                    class="remove-renter"
                    @click="removeRenter"
                  ><i class="f7-icons">multiply_circle_fill</i></a>
                </f7-block-title>
                <f7-list class="no-margin-vertical">
                  <ul>
                    <edl-info-input
                      label="Nom du locataire"
                      :path="getRenterPath(index, 'name')"
                      :info="info"
                      required
                      :reveal-errors="revealErrors"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Email du locataire"
                      :path="getRenterPath(index, 'email')"
                      :info="info"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Téléphone du locataire"
                      :path="getRenterPath(index, 'tel')"
                      :info="info"
                      @set="updateInfo"
                    />
                  </ul>
                </f7-list>
              </f7-col>
              <f7-col
                v-if="edlIO === 'sortant'"
                :medium="renterMediumCols"
                width="100"
                class="no-title no-margin-top-medium"
              >
                <f7-list class="no-margin-bottom">
                  <ul>
                    <edl-info-input
                      label="N˚ et voie"
                      :path="getRenterPath(index, 'sortantAddr')"
                      :info="info"
                      type="textarea"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Code postal"
                      :path="getRenterPath(index, 'sortantCodePostal')"
                      :info="info"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Ville"
                      :path="getRenterPath(index, 'sortantVille')"
                      :info="info"
                      @set="updateInfo"
                    />
                  </ul>
                </f7-list>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-list class="no-margin-top">
                  <ul>
                    <edl-info-toggle
                      v-if="garant"
                      label="Représenté par le représentant"
                      :path="getRenterPath(index, 'represented')"
                      :info="info"
                      @set="updateInfo"
                    />
                  </ul>
                </f7-list>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
      </transition-group>
      <f7-row
        v-if="nbRenters < 5"
        class="margin-bottom"
      >
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
        >
          <f7-button
            type="button"
            small
            @click="addRenter"
          >
            <i class="f7-icons">person_crop_circle_badge_plus</i> <span>{{ $t("Ajouter un locataire") }}</span>
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
      <f7-row>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            v-if="!valid"
            class="color-red"
            large
            fill
            raised
            disabled
          >
            {{ $t("Certains champs obligatoires ne sont pas remplis") }}
          </f7-button>
          <f7-button
            v-else-if="creating"
            large
            fill
            raised
            disabled
          >
            {{ $t("Création de l'EDL...") }}
          </f7-button>
          <f7-button
            v-else
            large
            fill
            raised
            @click="createEdl"
          >
            {{ $t("Créer l'EDL") }}
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
import firewall from "../../mixins/firewall";
import NavigationTop from "../../layout/NavigationTop";
import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";
import EdlInfoToggle from "../Edl/EdlInfo/EdlInfoToggle.vue";
import EdlInfoInput from "../Edl/EdlInfo/EdlInfoInput.vue";
import edlInfoBuilder from "../../../data/edlInfoBuilder";
import {removeSignaturesFromEdl, extractPhotosFromEdl} from "@/composables/photoStorage";
import autoEdl from "@/components/mixins/autoEdl";
import {unobserve} from "@/store/helper";
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { f7 } from "framework7-vue";
import {
  addDefaultRatesIfMissing,
  createCommonRateInfoFromIndividualRateInfos,
  fillIds,
  fixLegacyEmptyRates,
  fixLegacyEquipmentRates,
  fixLegacyIdRates,
  fixLegacyRateValues,
  fixLegacyTypeRates,
  presetTotalKeysCount,
  removeCosting,
  removeIndividualRateInfos,
  renameLegacyRates,
  resetCleaning,
  resetOpened,
  resetPreQuote,
  resetToQuote
} from "@/composables/prepare";

export default {
    name: "AutoEdlInfo",
    components: {
      NavigationTop,
      EdlInfoToggle,
      EdlInfoInput
    },
    mixins: [firewall, autoEdl],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    setup: () => ({ v$: useVuelidate() }),
    data: function () {
      return {
        info: null, //Info is temporary until "createEdl" is called, where it is persisted to store
        creating: false,
        valid: true,
        revealErrors: false
      };
    },
    validations: {
      info: {
        renter: {
          name: {required}
        }
      }
    },
    computed: {
      ...mapState('edlTemp', [
        'edlToDuplicate'
      ]),
      ...mapGetters('edlPersist', [
        'getLatestLocalEdlId',
        'getLocalEdlById'
      ]),
      title: function () {
        return 'Auto-Edl - Infos';
      },
      edlIO: function () {
        if(!this.info || this.info.edlIO === undefined) {
          return null;
        } else {
          switch(this.info.edlIO) {
            case 'Entrant':
              return 'sortant';
            case 'Sortant':
              return 'entrant';
            default:
              return 'entrant';
          }
        }
      },
      duplicateInfo: function () {
        return this.edlToDuplicate
          ? this.edlToDuplicate.data
          : null;
      },
      hasConventionSortie: function () {
        return this.edlIO === "sortant" || this.isCaseColloc;
      },
      garant: function () {
        return this.info && this.info.garant !== undefined ? this.info.garant : null;
      },
      nbRenters: function () {
        return this.info && this.info.nbRenters !== undefined ? this.info.nbRenters : null;
      },
      isCaseColloc: function () {
        return this.info && this.info.isCaseColloc !== undefined ? this.info.isCaseColloc : null;
      },
      renters: function () {
        if(!this.info){
          return null;
        }
        let renters = {};
        for (let index = 1; index <= this.nbRenters; index++) {
          let renterIndex = index > 1 ? 'renter'+index : 'renter';
          let renterInfo = this.info[renterIndex];
          if(renterInfo){
            renters[index] = renterInfo;
          }
        }
        return renters;
      },
      renterMediumCols: function () {
        return this.edlIO === 'sortant' ? 50 : 100;
      }
    },
    watch: {
      me: {
        handler: 'setup'
      },
      valid: function (isValid) {
        if (isValid === false) {
          this.revealErrors = true;
        }
      }
    },
    methods: {
      ...mapActions('edlTemp', [
        'fetchRemoteEdlById',
        'setEdlToDuplicate'
      ]),
      ...mapActions('edlPersist', [
        'addLocalEdl'
      ]),
      setup: function () {
        let newLocalId = Date.now();
        let duplicateFromId = this.me.autoEdlDuplicatedFrom.id;
        f7.dialog.preloader(this.$t('Préparation en cours, veuillez patienter...'));

        this.fetchRemoteEdlById(duplicateFromId).then((response) => {
          let edl = response.data;
          removeSignaturesFromEdl(edl).then((edl) => {
            extractPhotosFromEdl(edl, newLocalId).then((edl) => {
              this.setEdlToDuplicate(edl);
              this.info = unobserve(this.duplicateInfo);
              this.updateInfo({path: 'localId', value: newLocalId});
              this.updateInfo({path: 'open', value: true});
              this.updateInfo({path: 'duplicateFromEdlID', value: duplicateFromId});
              this.updateInfo({path: 'renter.name', value: null});
              this.updateInfo({path: 'renter.email', value: this.me.email});
              _.forEach(this.me.autoEdlPreData, (value, key) => {
                this.updateInfo({path: ['bien', key], value});
              });
              //Remove potential manual costing which will not be used in AutoEdls
              if(_.has(this.info, 'conventionSortieManuelle')) {
                this.removeInfo(['conventionSortieManuelle']);
              }
              f7.dialog.close();
            });
          });
        }).catch((error) => {
          console.error(error);
          f7.dialog.close();
        });
      },
      updateInfo: function ({path, value}) {
        _.set(this.info, path, value);
      },
      removeInfo: function (paths) {
        this.info = _.omit(this.info, paths);
      },
      createEdl: function () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.valid = false;
          setTimeout(() => {
            this.valid = true;
          }, 3000);
        } else {
          this.creating = true;
          this.updateInfo({path: 'date', value: Date.now()});
          this.updateInfo({path: 'edlIO', value: this.info.edlIO === 'Entrant' ? 'Sortant' : 'Entrant'});

          //Prepare duplicate EDL
          let edlToPrepare = unobserve(this.info.edl);
          edlToPrepare = resetCleaning(edlToPrepare, 'Propre');
          edlToPrepare = removeCosting(edlToPrepare);
          edlToPrepare = resetOpened(edlToPrepare);
          edlToPrepare = resetToQuote(edlToPrepare);
          edlToPrepare = resetPreQuote(edlToPrepare);
          edlToPrepare = fillIds(edlToPrepare);
          edlToPrepare = fixLegacyEmptyRates(edlToPrepare);
          edlToPrepare = fixLegacyRateValues(edlToPrepare);
          edlToPrepare = fixLegacyTypeRates(edlToPrepare);
          edlToPrepare = fixLegacyEquipmentRates(edlToPrepare);
          edlToPrepare = fixLegacyIdRates(edlToPrepare);
          edlToPrepare = presetTotalKeysCount(edlToPrepare);
          edlToPrepare = renameLegacyRates(edlToPrepare);
          this.updateInfo({path: 'commonRateInfo', value: createCommonRateInfoFromIndividualRateInfos(this.info, edlToPrepare)});
          edlToPrepare = removeIndividualRateInfos(edlToPrepare);
          edlToPrepare = addDefaultRatesIfMissing(edlToPrepare);

          this.updateInfo({path: 'edl', value: edlToPrepare});

          this.addLocalEdl(this.info);
          this.updateAutoEdl({id: this.userId, path: 'id', value: this.info.localId});

          this.setEdlToDuplicate(null);

          this.f7router.navigate({name: 'autoEdl'}, {reloadAll: true});
        }
      },
      addRenter: function () {
        if(this.info.nbRenters < 5) {
          this.info.nbRenters++;
          this.info['renter'+this.info.nbRenters] = edlInfoBuilder.newRenter();
        }
      },
      removeRenter: function () {
        if(this.info.nbRenters > 1) {
          delete this.info['renter'+this.info.nbRenters];
          this.info.nbRenters--;
        }
      },
      getRenterPath: function (index, property) {
        return index > 1 ? 'renter'+index+'.'+property : 'renter.'+property;
      },
      addGarant: function () {
        if(!this.garant) {
          this.info['garant'] = {'name': null};
        }
      },
      removeGarant: function () {
        if(this.garant) {
          this.info['garant'] = null;
        }
      },
      backToLang: function () {
        this.updateAutoEdl({id: this.userId, path: 'locale', value: null});
        this.f7router.navigate({name: 'autoEdlLang'}, {reloadAll: true});
      }
    }
  }
</script>

<style lang="scss" scoped>
  .no-title {
    margin-top: 30px;
  }
  .sortant-info {
    text-align: center;
    font-size: 12px;
    font-style: italic;
    color: var(--f7-theme-color-dark-grey);
  }
  .general-toggles {
    padding: 10px 10px 5px 10px;
    border-radius: 10px;
    background-color: var(--f7-theme-color-dark-grey);
    p {
      font-size: 14px;
      color: var(--f7-theme-color-light-grey);
      margin-top: 0px;
      margin-bottom: 5px;

      span {
        margin-left: 5px;
        &.active {
          font-weight: bold;
        }
      }
    }
  }
  .f7-icons {
    font-size: 16px;
  }
  .ios .button{
    transition-duration: 0.5s;
    &:hover {
      background: var(--f7-theme-color-pale);
    }
  }
  a.remove-renter {
    transition-duration: 0.3s;
    &:hover {
      color: var(--f7-theme-color-tint);
    }
  }
  .renter-enter-active, .renter-leave-active, .garant-enter-active {
    transition: all 1s;
  }
  .renter-enter, .renter-leave-to, .garant-enter /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-30px);
  }
  .renter-move, .garant-move {
    transition: transform 1s;
  }

  @media screen and (max-width: 768px) {
    .no-margin-bottom-medium {
      margin-bottom: 0;
      .list {
        margin-bottom: 0;
      }
    }
    .no-margin-top-medium {
      margin-top: 0;
      .list {
        margin-top: 0;
      }
    }
  }
</style>
