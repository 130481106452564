import _ from "lodash";

export const newEdlInfo = (edlType, edlTemplate = 'default') => {
  let info = {
    "open": false,
    "edlIO": _.upperFirst(edlType),
    "bien": {
      "meuble": "non",
      "presenceCave": "non",
      "presenceParking": "non",
      "genre": null,
      "typeLogement": null,
      "etat": null,
      "addr": null,
      "codePostal": null,
      "ville": null,
      "etage": null,
      "numAppart": null,
      "mandataire": null
    },
    "commonRateInfo": {},
    "nbRenters": 1,
    "owner": {"proprietaireNom": null},
    "renter": newRenter(),
    "date": Date.now(),
    "edl": null,
    "locale": "fr"
  };

  //Since old edls had no templates, only add "template" property if not default template
  if (edlTemplate !== 'default') {
    info.template = edlTemplate;
  }

  return info;
};

export const newRenter = () => {
  return {
    "name": null,
    "email": null,
    "tel": null
  };
};

export const getNewEdlDetails = (edl) => {
  let details = {};
  details.cellar = edl.bien.presenceCave === 'oui';
  details.garage = edl.bien.presenceParking === 'oui';
  details.toiletInBathroom = edl.bien.typeLogement === 'Studio';

  details.nbRooms = 1;
  let typeRegex = /T(?<type>[3-7])/; // T2 = 1 room, no adding
  let result = edl.bien.typeLogement.match(typeRegex);

  if (result && result.groups.type !== undefined) {
    let i = +result.groups.type;
    while (i-- > 2) {
      details.nbRooms++;
    }
  }

  if(edl.template !== undefined) {
    details.template = edl.template;
  }

  return details;
}

export default {
  newEdlInfo,
  newRenter,
  getNewEdlDetails
}
