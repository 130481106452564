<template>
  <f7-popup
    class="custom-legal-text-popup"
    tablet-fullscreen
    :opened="opened"
    @popup:closed="fixPopupCloseOnSafari"
    @popup:opened="adjustLayout"
  >
    <f7-page ref="popup">
      <f7-navbar
        ref="navbar"
        title="Mentions légales"
      >
        <f7-nav-right>
          <f7-link
            @click="close"
            icon-ios="f7:multiply_circle"
          />
        </f7-nav-right>
      </f7-navbar>
      <f7-block>
        <f7-block-title>EDL</f7-block-title>
        <f7-button
          small
          icon-f7="arrow_down_square"
          @click="fillEdlWithDefault"
        >
          Partir des mentions légales de DDH
        </f7-button>

        <f7-text-editor
          :value="localValueEdl"
          :buttons="buttons"
          @texteditor-change="updateEdl"
          :placeholder="placeholder"
          style="height: 40vh;"
        />
      </f7-block>
      <f7-block>
        <f7-block-title>Convention de sortie / Devis</f7-block-title>
        <f7-button
          small
          icon-f7="arrow_down_square"
          @click="fillConventionWithDefault"
        >
          Partir des mentions légales de DDH
        </f7-button>

        <f7-text-editor
          :value="localValueConvention"
          :buttons="buttons"
          @texteditor-change="updateConvention"
          :placeholder="placeholder"
          style="height: 40vh;"
        />
      </f7-block>
      <f7-block>
        <edl-submit-row
          name="Enregistrer"
          @submit="save"
        />
      </f7-block>
    </f7-page>
  </f7-popup>
</template>

<script>
import EdlSubmitRow from "@/components/pages/Edl/EdlSubmitRow.vue";
import {f7} from "framework7-vue";
import api from "@/api";

export default {
  name: "CustomLegalTextPopup",
  components: {EdlSubmitRow},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    edl: {
      type: String,
      default: null
    },
    convention: {
      type: String,
      default: null
    },
    context: {
      type: String,
      default: null,
      required: true,
      validator: function (value) {
        return ['user', 'group', 'subgroup'].includes(value);
      }
    }
  },
  emits: ['update', 'close'],
  data: function () {
    return {
      localValueEdl: this.edl,
      localValueConvention: this.convention,
      buttons: [
        ['bold', 'italic', 'underline'],
        ['orderedList', 'unorderedList'],
        ['link'],
        ['subscript', 'superscript'],
        ['undo', 'redo']
      ]
    };
  },
  computed: {
    placeholder: function () {
      let placeholder = 'Personnalisez ici les mentions légales,';
      if(this.context === 'user') {
        placeholder += " elles remplaceront celles de DDH (ou celles de votre groupe)..."
      } else if(this.context === 'group') {
        placeholder += " elles remplaceront celles de DDH..."
      } else if(this.context === 'subgroup') {
        placeholder += " elles remplaceront celles de DDH (ou celles de votre groupe parent)..."
      }

      return placeholder;
    }
  },
  watch: {
    edl: {
      handler: function (newValue) {
        this.localValueEdl = newValue;
      },
      immediate: true
    },
    convention: {
      handler: function (newValue) {
        this.localValueConvention = newValue;
      },
      immediate: true
    }
  },
  created() {
    window.addEventListener("resize", this.adjustLayout);
  },
  unmounted() {
    window.removeEventListener("resize", this.adjustLayout);
  },
  methods: {
    updateEdl: function (newValue) {
      this.localValueEdl = newValue;
    },
    updateConvention: function (newValue) {
      this.localValueConvention = newValue;
    },
    save: function () {
      let edl = this.localValueEdl;
      let convention = this.localValueConvention;

      //Case an existing text is now empty, there remains a <br> that we remove here
      if(edl === '<br>') {
        edl = '';
      }
      if(convention === '<br>') {
        convention = '';
      }

      this.$emit('update', {
        edl,
        convention
      });
      this.$emit('close');
    },
    fillEdlWithDefault: function () {
      if(this.localValueEdl !== null && this.localValueEdl !== '' && this.localValueEdl !== '<br>') {
        f7.dialog.confirm('Êtes-vous certain de vouloir remplacer vos mentions légales de l\'EDL par celles de DDH ?', 'Confirmer', () => {
          this.doFillWithDefaultEdl();
        });
      } else { //is empty, don't confirm
        this.doFillWithDefaultEdl();
      }
    },
    doFillWithDefaultEdl: function () {
      api.getDefaultLegalText('edl').then(response => {
        this.localValueEdl = response.data;
      });
    },
    fillConventionWithDefault: function () {
      if(this.localValueConvention !== null && this.localValueConvention !== '' && this.localValueConvention !== '<br>') {
        f7.dialog.confirm('Êtes-vous certain de vouloir remplacer vos mentions légales de la convention de sortie par celles de DDH ?', 'Confirmer', () => {
          this.doFillWithDefaultConvention();
        });
      } else { //is empty, don't confirm
        this.doFillWithDefaultConvention();
      }
    },
    doFillWithDefaultConvention: function () {
      api.getDefaultLegalText('convention').then(response => {
        this.localValueConvention = response.data;
      });
    },
    adjustLayout: function () {
      let alwaysShow = this.$refs.alwaysShow;
      let popup = this.$refs.popup;
      let navbar = this.$refs.navbar;

      this.scrollUnderStyle =
        (alwaysShow !== undefined && popup !== undefined && navbar !== undefined) ? {
          height: (popup.$el.clientHeight - navbar.$el.clientHeight - alwaysShow.clientHeight - 10) + 'px'
        } : null;
    },
    close: function () {
      this.$emit('close');
    },
    fixPopupCloseOnSafari: function () {
      //Patch to fix Safari issue, popup still rendered on screen after close until resize
      window.dispatchEvent(new Event('resize'));
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-under {
  border-top: 1px solid var(--f7-block-strong-border-color);
  overflow-y: scroll;
}
.center {
  text-align: center;
}
.arrow-icon i {
  font-size: 16px;
}
.section-icon {
  position: relative;
  top: 20px;
  left: 2px;
  font-size: 14px;
  z-index: 2;
}
</style>
