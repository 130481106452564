<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top :title="title" />
    <navigation-bottom current="new" />

    <f7-page-content>
      <f7-row>
        <f7-col
          medium="50"
          width="100"
          class="no-margin-bottom-medium"
        >
          <f7-block-title><i class="f7-icons">info_circle_fill</i> Informations sur le bien</f7-block-title>
          <f7-list>
            <ul>
              <f7-list-input
                v-if="isConnectedToPlatform"
                label="Récupérer un dossier plateforme DDH"
                type="select"
                floating-label
                :value="info?.idDossierPlateformeDDH"
                @change="autoFillInfoFromPlatformCase"
              >
                <option value=""></option>
                <option
                  v-for="platformCase in platformCases"
                  :key="'platformCase_'+platformCase.id"
                  :value="platformCase.id"
                >
                  {{ platformCase.regieEntreprise }} / {{ getPlatformCaseRenter(platformCase) }} (ref. {{ platformCase.ref }})
                </option>
              </f7-list-input>
              <edl-info-select
                label="Genre du bien"
                path="bien.genre"
                :info="info"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-select
                label="Type de bien"
                path="bien.typeLogement"
                :info="info"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-select
                label="Etat du bien"
                path="bien.etat"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-toggle
                v-if="hasChangedAfterDuplication('bien.etat')"
                conditional
                :label="etatReinitLabel"
                path="bien.etatReinit"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-toggle
                label="Meublé ?"
                path="bien.meuble"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-toggle
                label="Présence d'une cave ?"
                path="bien.presenceCave"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-input
                conditional
                label="N˚ de cave"
                path="bien.numeroCave"
                case="bien.presenceCave"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-toggle
                label="Présence d'un garage/parking ?"
                path="bien.presenceParking"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-input
                conditional
                label="N˚ de parking"
                path="bien.numeroParking"
                case="bien.presenceParking"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-toggle
                label="Présence d'un grenier ?"
                path="bien.presenceGrenier"
                :info="info"
                @set="updateInfo"
              />
            </ul>
          </f7-list>
        </f7-col>
        <f7-col
          medium="50"
          width="100"
          class="no-title no-margin-top-medium"
        >
          <f7-list>
            <ul>
              <edl-info-input
                label="Propriétaire"
                path="owner.proprietaireNom"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-input
                label="Mandataire"
                path="bien.mandataire"
                :info="info"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-input
                label="N˚ et voie"
                path="bien.addr"
                :info="info"
                type="textarea"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-input
                label="Code postal"
                path="bien.codePostal"
                :info="info"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-input
                label="Ville"
                path="bien.ville"
                :info="info"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-input
                label="Étage et porte"
                path="bien.etage"
                :info="info"
                required
                :reveal-errors="revealErrors"
                @set="updateInfo"
              />
              <edl-info-input
                label="N˚ appartement"
                path="bien.numAppart"
                :info="info"
                @set="updateInfo"
              />
              <edl-info-input
                v-if="edlIO === 'sortant'"
                label="Date de l'EDL entrant"
                path="entryDate"
                :info="info"
                type="datepicker"
                :initial-value="initialEntryDate"
                @set="updateInfo"
              />
            </ul>
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row
        v-if="!garant"
        class="margin-top"
      >
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
        >
          <f7-button
            icon-f7="person_2_fill"
            text="Définir un Representant"
            type="button"
            @click="addGarant"
          />
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>

      <transition name="garant">
        <f7-row v-if="garant">
          <f7-col>
            <f7-block-title>
              <i class="f7-icons">person_2_fill</i> Représentant
              <a
                class="remove-garant"
                @click="removeGarant"
              ><i class="f7-icons">multiply_circle_fill</i></a>
            </f7-block-title>
            <f7-list>
              <ul>
                <edl-info-input
                  label="Nom du représentant"
                  path="garant.name"
                  :info="info"
                  @set="updateInfo"
                />
              </ul>
            </f7-list>
          </f7-col>
        </f7-row>
      </transition>

      <transition-group
        name="renter"
        tag="div"
      >
        <f7-row
          v-for="(renter, index) in renters"
          :key="'renter'+index"
        >
          <f7-col>
            <f7-row>
              <f7-col
                :medium="renterMediumCols"
                width="100"
                class="no-margin-bottom-medium"
              >
                <f7-block-title>
                  <i class="f7-icons">person_crop_circle</i> Locataire {{ edlIO }} {{ (index > 1) ? '#'+index : '' }}
                  <a
                    v-show="index > 1 && +index === nbRenters"
                    class="remove-renter"
                    @click="removeRenter"
                  ><i class="f7-icons">multiply_circle_fill</i></a>
                </f7-block-title>
                <f7-list class="no-margin-vertical">
                  <ul>
                    <edl-info-input
                      label="Nom du locataire"
                      :path="getRenterPath(index, 'name')"
                      :info="info"
                      required
                      :reveal-errors="revealErrors"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Email du locataire"
                      type="email"
                      :path="getRenterPath(index, 'email')"
                      :info="info"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Téléphone du locataire"
                      type="tel"
                      :path="getRenterPath(index, 'tel')"
                      :info="info"
                      @set="updateInfo"
                    />
                  </ul>
                </f7-list>
              </f7-col>
              <f7-col
                v-if="edlIO === 'sortant'"
                :medium="renterMediumCols"
                width="100"
                class="no-title no-margin-top-medium"
              >
                <f7-list class="no-margin-bottom">
                  <ul>
                    <edl-info-input
                      label="N˚ et voie"
                      :path="getRenterPath(index, 'sortantAddr')"
                      :info="info"
                      type="textarea"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Code postal"
                      :path="getRenterPath(index, 'sortantCodePostal')"
                      :info="info"
                      @set="updateInfo"
                    />
                    <edl-info-input
                      label="Ville"
                      :path="getRenterPath(index, 'sortantVille')"
                      :info="info"
                      @set="updateInfo"
                    />
                  </ul>
                </f7-list>
              </f7-col>
            </f7-row>
            <f7-row>
              <f7-col>
                <f7-list class="no-margin-top">
                  <ul>
                    <edl-info-toggle
                      v-if="garant"
                      label="Le représentant signe à la place de ce locataire"
                      :path="getRenterPath(index, 'represented')"
                      :info="info"
                      @set="updateInfo"
                    />
                  </ul>
                </f7-list>
              </f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
      </transition-group>
      <f7-row
        v-if="nbRenters < 5"
        class="margin-bottom"
      >
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
        >
          <f7-button
            icon-f7="person_crop_circle_badge_plus"
            type="button"
            small
            text="Ajouter un locataire"
            @click="addRenter"
          />
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>

      <f7-row v-if="nbExtraEmails > 0">
        <f7-col>
          <f7-row v-for="index in nbExtraEmails" :key="'extraEmail'+index">
            <f7-col>
              <f7-block-title>
                <i class="f7-icons">at_circle</i> Email supplémentaire {{ index > 1 ? '#'+index : '' }}
                <a
                  class="remove-extra-email"
                  @click="removeExtraEmail"
                ><i class="f7-icons">multiply_circle_fill</i></a>
              </f7-block-title>
              <f7-list>
                <ul>
                  <edl-info-input
                    label="Adresse email qui recevra l'EDL"
                    :path="getExtraEmailPath(index)"
                    :info="info"
                    @set="updateInfo"
                  />
                </ul>
              </f7-list>
            </f7-col>
          </f7-row>
        </f7-col>
      </f7-row>
      <f7-row
        v-if="nbExtraEmails < 3"
        class="margin-bottom"
      >
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
        >
          <f7-button
            icon-f7="at_badge_plus"
            text="Ajouter un email supplémentaire"
            type="button"
            small
            @click="addExtraEmail"
          />
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>

      <f7-row v-if="missingPhotosOnDuplicationCount">
        <f7-col
          small="20"
          medium="30"
          width="10"
        />
        <f7-col
          small="60"
          medium="40"
          width="80"
        >
          <p
            v-if="missingPhotosOnDuplicationCount > 1"
            class="sortant-missing-photos no-margin-top margin-bottom"
          >
            Attention, <strong>{{ missingPhotosOnDuplicationCount }} photos</strong> du document d’origine ne seront pas affichées car elles étaient endommagées lors du téléchargement de l'état des lieux initial.
          </p>
          <p
            v-if="missingPhotosOnDuplicationCount === 1"
            class="sortant-missing-photos no-margin-top margin-bottom"
          >
            Attention, <strong>1 photo</strong> du document d’origine ne sera pas affichée car elle était endommagée lors du téléchargement de l'état des lieux initial.
          </p>
        </f7-col>
        <f7-col
          small="20"
          medium="30"
          width="10"
        />
      </f7-row>
      <f7-row
        v-if="hasConventionSortie || isDuplicate"
        class="margin-bottom"
      >
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="general-toggles"
        >
          <p v-if="hasConventionSortie">
            <f7-toggle @change="toggleConventionSortieManuelle" />
            <span :class="{active: conventionSortieManuelle}"> Convention de sortie manuelle</span>
          </p>
          <p v-if="isDuplicate">
            <f7-toggle @change="optionResetCleaning = !optionResetCleaning" />
            <span :class="{active: optionResetCleaning}"> Réinitialiser ménage</span>
          </p>
          <p v-if="isDuplicate">
            <f7-toggle @change="optionResetPhotos = !optionResetPhotos" />
            <span :class="{active: optionResetPhotos}"> Supprimer les photos existantes</span>
          </p>
          <p v-if="isDuplicate">
            <f7-toggle @change="optionResetRenovation = !optionResetRenovation" />
            <span :class="{active: optionResetRenovation}"> Rénovation générale</span>
          </p>
          <p v-if="isDuplicate">
            <f7-toggle @change="optionResetPainting = !optionResetPainting" />
            <span :class="{active: optionResetPainting}"> Rafraîchissement peintures (concerne les murs, plafonds, plinthes et portes)</span>
          </p>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
      <f7-row>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            v-if="!valid"
            class="color-red"
            large
            fill
            raised
            disabled
          >
            Certains champs obligatoires ne sont pas remplis
          </f7-button>
          <f7-button
            v-else-if="creating"
            large
            fill
            raised
            disabled
          >
            Création de l'EDL...
          </f7-button>
          <f7-button
            v-else-if="isEdit"
            large
            fill
            raised
            @click="editEdl"
          >
            Valider et retourner à l'EDL
          </f7-button>
          <f7-button
            v-else
            large
            fill
            raised
            @click="createEdl"
          >
            Créer l'EDL
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
      <f7-row v-if="edlIO === 'sortant' && !isEdit">
        <f7-col>
          <p class="sortant-info no-margin-top margin-bottom">
            Cet EDL sortant sera comparé à son EDL entrant
          </p>
        </f7-col>
      </f7-row>

    </f7-page-content>
  </f7-page>
</template>

<script>
import firewall from "../../../mixins/firewall";
import NavigationTop from "../../../layout/NavigationTop.vue";
import NavigationBottom from "../../../layout/NavigationBottom.vue";
import {mapActions, mapGetters, mapState} from "vuex";
import _ from "lodash";
import EdlInfoSelect from "./EdlInfoSelect.vue";
import EdlInfoToggle from "./EdlInfoToggle.vue";
import EdlInfoInput from "./EdlInfoInput.vue";
import edlInfoBuilder from "../../../../data/edlInfoBuilder";
import {
  deleteEdlPhotos,
  duplicatePhotosFromEdl,
  extractPhotosFromEdl,
  removeSignaturesFromEdl
} from "@/composables/photoStorage";
import {unobserve} from "@/store/helper";
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { f7 } from "framework7-vue";
import {nextTick} from "vue";
import {getPlatformCases} from "@/api";
import {
  addDefaultRatesIfMissing,
  clearExtraEmails,
  clearGarant,
  clearRenters,
  createCommonRateInfoFromIndividualRateInfos,
  fillIds,
  fixLegacyEmptyRates,
  fixLegacyEquipmentRates,
  fixLegacyIdRates,
  fixLegacyRateValues,
  fixLegacyTypeRates,
  presetTotalKeysCount,
  removeCosting,
  removeIndividualRateInfos, renameLegacyRates,
  resetCleaning,
  resetCondition,
  resetOpened,
  resetPainting,
  resetPhotos,
  resetPreQuote,
  resetRenovation,
  resetToQuote,
  setDefaultLocaleIfMissing
} from "@/composables/prepare";

export default {
    name: "EdlInfo",
    components: {
      NavigationTop,
      NavigationBottom,
      EdlInfoSelect,
      EdlInfoToggle,
      EdlInfoInput
    },
    mixins: [firewall],
    props: {
      edlType: {
        type: String,
        default: null
      },
      edlTemplate: {
        type: String,
        default: 'default'
      },
      duplicateFromId: {
        type: String,
        default: null
      },
      localId : {
        type: String,
        default: null
      },
      source: {
        type: String,
        default: 'remote'
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    setup: () => ({ v$: useVuelidate() }),
    data: function () {
      return {
        info: null, //Info is temporary until "createEdl" is called, where it is persisted to store
        platformCases: null,
        creating: false,
        valid: true,
        revealErrors: false,
        optionResetCleaning: false,
        optionResetPhotos: false,
        optionResetRenovation: false, //resets conditions to "bon état" + deletes observations
        optionResetPainting: false //resets conditions to "bon état" + deletes observations - only for walls, ceilings, doors...
      };
    },
    validations: {
      info: {
        bien: {
          genre: {required},
          typeLogement: {required},
          mandataire: {required},
          addr: {required},
          codePostal: {required},
          ville: {required},
          etage: {required},
        },
        renter: {
          name: {required}
        }
      }
    },
    computed: {
      ...mapState('edlTemp', [
        'edlToDuplicate'
      ]),
      ...mapGetters('edlPersist', [
        'getLatestLocalEdlId',
        'getLocalEdlById',
        'getCurrentLocalEdl'
      ]),
      isNew: function () {
        return this.edlType !== null && !this.isDuplicate;
      },
      isDuplicate: function () {
        return this.duplicateFromId !== null;
      },
      isEdit: function () {
        return this.localId !== null;
      },
      title: function () {
        if(this.isNew) {
          return 'Nouvel EDL ' + this.edlType;
        } else if (this.isEdit) {
          return 'Modification des infos de l\'EDL ' + this.edlIO;
        } else {
          let title;
          if(this.source === 'remote') {
            title = 'Duplication de l\'EDL #'+this.duplicateFromId;
          } else {
            title = 'Duplication d\'un EDL local';
          }
          if(this.edlIO) {
            title += ' - Nouvel EDL '+this.edlIO;
          }
          return title;
        }
      },
      edlIO: function () {
        if (this.edlType) {
          return this.edlType;
        } else if(!this.info || this.info.edlIO === undefined) {
          return null;
        } else {
          return this.info.edlIO.toLowerCase(); //case edit
        }
      },
      duplicateInfo: function () {
        return this.edlToDuplicate
          ? (this.source === 'remote' ? this.edlToDuplicate.data : this.edlToDuplicate)
          : null;
      },
      hasConventionSortie: function () {
        return this.isDuplicate && (this.edlIO === "sortant" || this.isCaseColloc);
      },
      garant: function () {
        return this.info && this.info.garant !== undefined ? this.info.garant : null;
      },
      nbRenters: function () {
        return this.info && this.info.nbRenters !== undefined ? this.info.nbRenters : null;
      },
      nbExtraEmails: function () {
        return this.info && this.info.nbExtraEmails !== undefined ? this.info.nbExtraEmails : null;
      },
      isCaseColloc: function () {
        return this.edlToDuplicate && this.edlToDuplicate.isCaseColloc !== undefined ? this.edlToDuplicate.isCaseColloc : null;
      },
      missingPhotosOnDuplicationCount: function () {
        return this.edlToDuplicate && this.edlToDuplicate.missingPhotosOnDuplicationCount !== undefined ? this.edlToDuplicate.missingPhotosOnDuplicationCount : null;
      },
      conventionSortieManuelle: function () {
        return this.info && this.info.conventionSortieManuelle !== undefined ? this.info.conventionSortieManuelle : false;
      },
      renters: function () {
        if(!this.info){
          return null;
        }
        let renters = {};
        for (let index = 1; index <= this.nbRenters; index++) {
          let renterIndex = index > 1 ? 'renter'+index : 'renter';
          let renterInfo = this.info[renterIndex];
          if(renterInfo){
            renters[index] = renterInfo;
          }
        }
        return renters;
      },
      extraEmails: function () {
        if(!this.info){
          return null;
        }
        let extraEmails = {};
        for (let index = 1; index <= this.nbExtraEmails; index++) {
          let extraEmailIndex = index > 1 ? 'extraEmail'+index : 'extraEmail';
          let extraEmailInfo = this.info[extraEmailIndex];
          if(extraEmailInfo !== undefined){
            extraEmails[index] = extraEmailInfo;
          }
        }
        return extraEmails;
      },
      renterMediumCols: function () {
        return this.edlIO === 'sortant' ? 50 : 100;
      },
      initialEntryDate: function () {
        if(this.info
          && (this.info.entryDate === undefined || this.info.entryDate === null) //not edit
          && this.isDuplicate
          && this.duplicateInfo
          && this.duplicateInfo.date !== undefined && this.duplicateInfo.date !== null
          && this.duplicateInfo.edlIO === "Entrant"
        ) {
          return new Date(this.duplicateInfo.date);
        }
        return null;
      },
      etatReinitLabel: function () {
        return this.hasChangedAfterDuplication('bien.etat') && this.info ?
          (this.info.bien.etat !== "" ?
            'Changer tous les états de l\'EDL en "'+this.info.bien.etat+'" ?'
              : 'Vider tous les états de l\'EDL ?'
          ) : '';
      }
    },
    watch: {
      valid: function (isValid) {
        if (isValid === false) {
          this.revealErrors = true;
        }
      }
    },
    mounted: function () {
      let newLocalId = Date.now();
      if (this.isNew) { //NEW EDL
        this.info = edlInfoBuilder.newEdlInfo(this.edlType, this.edlTemplate);
        this.updateInfo({path: 'localId', value: newLocalId});
        this.updateInfo({path: 'open', value: true});
      } else if (this.isEdit) { //EDIT EDL
        let localId = +this.localId; //converts to number
        this.setCurrentLocalEdlId(localId);
        let edl = unobserve(this.getCurrentLocalEdl);
        this.info = _.omit(edl, ['edl']);
      } else if (this.isDuplicate) { //EDL DUPLICATION - CALLS API
        nextTick(() => {
          f7.dialog.preloader('Duplication en cours, veuillez patienter...');
        });

        if (this.source === 'remote') {
          this.fetchRemoteEdlById(this.duplicateFromId).then((response) => {
            let edl = response.data;
            removeSignaturesFromEdl(edl).then((edl) => {
              extractPhotosFromEdl(edl, newLocalId).then((edl) => {
                this.setEdlToDuplicate(edl);
                this.info = unobserve(this.duplicateInfo);
                if(this.duplicateInfo.edlIO === 'Sortant') {
                  this.info = clearRenters(this.info);
                }
                this.info = clearExtraEmails(this.info);
                this.info = clearGarant(this.info);
                this.info = setDefaultLocaleIfMissing(this.info);
                this.updateInfo({path: 'localId', value: newLocalId});
                this.updateInfo({path: 'open', value: true});
                this.updateInfo({path: 'duplicateFromEdlID', value: this.duplicateFromId});
                this.updateInfo({path: 'duplicateFromEdlIO', value: this.duplicateInfo.edlIO});

                //Don't carry manual costing from duplication
                if(_.has(this.info, 'conventionSortieManuelle')) {
                  delete this.info.conventionSortieManuelle;
                }

                if (this.info.nbRenters === undefined) { //case old EDLs not having this property set
                  let nbRenters = _.reduce(this.info, (cpt, value, key) => {
                    if(key.includes('renter')) {
                      cpt++;
                    }
                    return cpt;
                  }, 0);
                  this.updateInfo({path: 'nbRenters', value: nbRenters});
                }

                //Preventively load calibrations for User if they haven't been loaded yet, to use in recap as we may be offline after
                this.fetchUserCalibration({userId: this.me.id});
                this.fetchCalibrationConfig({});

                f7.dialog.close();
              }).catch((error) => {
                console.error(error);
              });
            }).catch((error) => {
              console.error(error);
            });
          }).catch((error) => {
            console.error(error);
            f7.dialog.close();
          });
        } else if (this.source === 'local') { //LOCAL DUPLICATION
          let duplicateLocalId = +this.duplicateFromId;
          let edl = this.getLocalEdlById(duplicateLocalId);
          removeSignaturesFromEdl(unobserve(edl)).then((edl) => {
            duplicatePhotosFromEdl(edl, duplicateLocalId, newLocalId).then((edl) => {
              this.setEdlToDuplicate(edl);
              this.info = unobserve(this.duplicateInfo);
              if(this.duplicateInfo.edlIO === 'Sortant') {
                this.info = clearRenters(this.info);
              }
              this.info = clearExtraEmails(this.info);
              this.info = clearGarant(this.info);
              this.info = setDefaultLocaleIfMissing(this.info);
              this.updateInfo({path: 'localId', value: newLocalId});
              this.updateInfo({path: 'open', value: true});
              this.updateInfo({path: 'duplicateFromEdlID', value: this.duplicateFromId});
              this.updateInfo({path: 'duplicateFromEdlIO', value: this.duplicateInfo.edlIO});
              this.updateInfo({path: 'date', value: Date.now()});
              this.removeInfo(['id', 'syncDate']);

              //Don't carry manual costing from duplication
              if(_.has(this.info, 'conventionSortieManuelle')) {
                this.removeInfo(['conventionSortieManuelle']);
              }

              f7.dialog.close();
            }).catch((error) => {
              console.error(error);
            });
          }).catch((error) => {
            console.error(error);
          });
        }
      } else {
        console.error('Edl is neither new nor a duplicate');
      }

      //Platform connection
      if(this.isConnectedToPlatform) {
        getPlatformCases(this.me.platformToken).then((response) => {
          this.platformCases = _.pickBy(response.data, (platformCase) => {
            return _.includes([
              'Prestations souhaitées',
              'Prestations en cours'
            ], platformCase.status);
          });
        });
      }
    },
    methods: {
      ...mapActions('edlTemp', [
        'fetchRemoteEdlById',
        'setEdlToDuplicate'
      ]),
      ...mapActions('edlPersist', [
        'addLocalEdl',
        'setCurrentLocalEdlId',
        'updateCurrentLocalEdlInfo'
      ]),
      ...mapActions('costing', [
        'fetchUserCalibration',
        'fetchCalibrationConfig'
      ]),
      updateInfo: function ({path, value}) {
        _.set(this.info, path, value);
      },
      removeInfo: function (paths) {
        this.info = _.omit(this.info, paths);
      },
      createEdl: async function () { //async due to await for resetPhotos
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.valid = false;
          setTimeout(() => {
            this.valid = true;
          }, 3000);
        } else {
          this.creating = true;
          this.updateInfo({path: 'date', value: Date.now()});
          if (this.isNew) {
            this.updateInfo({path: 'edlIO', value: _.capitalize(this.edlIO)});
          } else { //duplicate
            this.updateInfo({path: 'edlIO', value: _.capitalize(this.edlIO)});

            //Prepare duplicate EDL
            let edlToPrepare = unobserve(this.info.edl);
            if (this.optionResetCleaning === true) {
              edlToPrepare = resetCleaning(edlToPrepare, 'Propre');
            }
            if (this.info.bien.etatReinit !== undefined && this.info.bien.etatReinit === "oui") {
              edlToPrepare = resetCondition(edlToPrepare, this.info.bien.etat);
              _.unset(this.info, 'bien.etatReinit');
            }
            if (this.optionResetPhotos === true) {
              edlToPrepare = await deleteEdlPhotos(edlToPrepare);
              edlToPrepare = resetPhotos(edlToPrepare);
            }
            if (this.optionResetPainting === true && !this.optionResetRenovation) { //no need if resetRenovation (overlaps)
              edlToPrepare = resetPainting(edlToPrepare);
            }
            if (this.optionResetRenovation === true) {
              edlToPrepare = resetRenovation(edlToPrepare);
              if(!this.optionResetCleaning) { //if not already done
                edlToPrepare = resetCleaning(edlToPrepare, 'Propre');
              }
            }
            edlToPrepare = removeCosting(edlToPrepare);
            edlToPrepare = resetOpened(edlToPrepare);
            edlToPrepare = resetToQuote(edlToPrepare);
            edlToPrepare = resetPreQuote(edlToPrepare);
            edlToPrepare = fillIds(edlToPrepare);
            edlToPrepare = fixLegacyEmptyRates(edlToPrepare);
            edlToPrepare = fixLegacyRateValues(edlToPrepare);
            edlToPrepare = fixLegacyTypeRates(edlToPrepare);
            edlToPrepare = fixLegacyEquipmentRates(edlToPrepare);
            edlToPrepare = fixLegacyIdRates(edlToPrepare);
            edlToPrepare = presetTotalKeysCount(edlToPrepare);
            edlToPrepare = renameLegacyRates(edlToPrepare);
            this.updateInfo({path: 'commonRateInfo', value: createCommonRateInfoFromIndividualRateInfos(this.info, edlToPrepare)});
            edlToPrepare = removeIndividualRateInfos(edlToPrepare);
            edlToPrepare = addDefaultRatesIfMissing(edlToPrepare);

            this.updateInfo({path: 'edl', value: edlToPrepare});
          }
          this.addLocalEdl(this.info);
          this.setEdlToDuplicate(null); //not needed anymore - save localstorage space

          this.f7router.navigate({
            name: 'edl',
            params: {
              localId: this.info.localId
            },
            clearPreviousHistory: true
          }, {reloadAll: true});
        }
      },
      editEdl: function () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          this.valid = false;
          setTimeout(() => {
            this.valid = true;
          }, 3000);
        } else {
          this.updateCurrentLocalEdlInfo(this.info);
          this.f7router.navigate({
            name: 'edl',
            params: {
              localId: this.localId
            },
            clearPreviousHistory: true
          }, {reloadAll: true});
        }
      },
      addRenter: function () {
        if(this.info.nbRenters < 5) {
          this.info.nbRenters++;
          this.info['renter'+this.info.nbRenters] = edlInfoBuilder.newRenter();
        }
      },
      removeRenter: function () {
        if(this.info.nbRenters > 1) {
          delete this.info['renter'+this.info.nbRenters];
          this.info.nbRenters--;
        }
      },
      getRenterPath: function (index, property) {
        return index > 1 ? 'renter'+index+'.'+property : 'renter.'+property;
      },
      addExtraEmail: function () {
        if(this.info.nbExtraEmails === undefined) {
          this.info.nbExtraEmails = 1;
        } else if(this.info.nbExtraEmails < 3) {
          this.info.nbExtraEmails++;
        }
        this.info['extraEmail'+(this.info.nbExtraEmails > 1 ? this.info.nbExtraEmails : '')] = '';
      },
      removeExtraEmail: function () {
        if(this.info.nbExtraEmails > 0) {
          delete this.info['extraEmail'+this.info.nbExtraEmails];
          this.info.nbExtraEmails--;
        }
      },
      getExtraEmailPath: function (index) {
        return index > 1 ? 'extraEmail'+index : 'extraEmail';
      },
      addGarant: function () {
        if(!this.garant) {
          this.info['garant'] = {'name': null};
        }
      },
      removeGarant: function () {
        if(this.garant) {
          this.info['garant'] = null;
        }
      },
      toggleConventionSortieManuelle: function () {
        this.info['conventionSortieManuelle'] = !this.conventionSortieManuelle;
      },
      hasChangedAfterDuplication: function (path) {
        if(!this.duplicateInfo || this.isNew || this.isEdit || this.isAutoEdl) {
          return false;
        }
        let current = _.get(this.info, path);
        let original = _.get(this.duplicateInfo, path);

        return current !== original;
      },
      getPlatformCaseRenter: function(platformCase) {
        if(platformCase.json !== undefined && platformCase.json !== null)  {
          const data = JSON.parse(platformCase.json);
          switch(data.edlIO) {
            case "Entrant":
              return data.renter.entrantName;
            case "Sortant":
              return data.renter.sortantName;
            case "Entrant et sortant":
              return data.renter.entrantName + ' => ' + data.renter.sortantName;
          }
        }
        return '';
      },
      autoFillInfoFromPlatformCase: function(event) {
        if(event.target.value !== null) {
          let platformCase = _.cloneDeep(_.find(this.platformCases, (value) => {
            return value.id === event.target.value;
          }));

          if(platformCase !== undefined) {
            f7.dialog.create({
              text: "Souhaitez-vous remplir les informations du bien grâce aux données du dossier ?",
              title: "Plateforme DDH",
              buttons: [
                {
                  text: 'Non',
                  bold: false,
                  close: true,
                  onClick: () => {
                    this.updateInfo({path: 'idDossierPlateformeDDH', value: platformCase.id});
                  }
                },
                {
                  text: 'Oui',
                  bold: true,
                  close: true,
                  onClick: () => {
                    let platformCaseData = JSON.parse(platformCase.json);

                    this.updateInfo({path: 'bien', value: platformCaseData.bien});

                    this.updateInfo({
                      path: ['owner', 'proprietaireNom'],
                      value: platformCaseData.owner.proprietaireNom
                    });
                    this.updateInfo({
                      path: ['bien', 'mandataire'],
                      value: platformCase.regieEntreprise + ' (' + platformCase.gestionnaireNom + ')'
                    });
                    this.updateInfo({path: 'idDossierPlateformeDDH', value: platformCase.id});

                    if (platformCaseData.edlIO === 'Entrant') {
                      this.updateInfo({path: ['renter', 'name'], value: platformCaseData.renter.entrantName});
                      this.updateInfo({path: ['renter', 'tel'], value: platformCaseData.renter.entrantTel});
                      this.updateInfo({path: ['renter', 'email'], value: platformCaseData.renter.entrantEmail});
                    } else if (platformCaseData.edlIO === 'Sortant') {
                      this.updateInfo({path: ['renter', 'name'], value: platformCaseData.renter.sortantName});
                      this.updateInfo({path: ['renter', 'tel'], value: platformCaseData.renter.sortantTel});
                      this.updateInfo({path: ['renter', 'email'], value: platformCaseData.renter.sortantEmail});
                    }
                  }
                }
              ]
            }).open();
          }
        } else {
          this.updateInfo({path: 'idDossierPlateformeDDH', value: null});
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .no-title {
    margin-top: 30px;
  }
  .sortant-info {
    text-align: center;
    font-size: 12px;
    font-style: italic;
    color: var(--f7-theme-color-dark-grey);
  }
  .general-toggles {
    padding: 10px 10px 5px 10px;
    border-radius: 10px;
    background-color: var(--f7-theme-color-dark-grey);
    p {
      font-size: 14px;
      color: var(--f7-theme-color-light-grey);
      margin-top: 0px;
      margin-bottom: 5px;

      span {
        margin-left: 5px;
        &.active {
          font-weight: bold;
        }
      }
    }
  }
  .f7-icons {
    font-size: 16px;
  }
  .ios .button{
    transition-duration: 0.5s;
  }
  a.remove-renter {
    transition-duration: 0.3s;
    &:hover {
      color: var(--f7-theme-color-tint);
    }
  }
  .renter-enter-active, .renter-leave-active, .garant-enter-active {
    transition: all 1s;
  }
  .renter-enter, .renter-leave-to, .garant-enter /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-30px);
  }
  .renter-move, .garant-move {
    transition: transform 1s;
  }
  a.remove-extra-email {
    transition-duration: 0.3s;
    &:hover {
      color: var(--f7-theme-color-tint);
    }
  }
  .extra-email-enter-active, .extra-email-leave-active, .garant-enter-active {
    transition: all 1s;
  }
  .extra-email-enter, .extra-email-leave-to, .garant-enter /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-30px);
  }
  .extra-email-move, .garant-move {
    transition: transform 1s;
  }
  .sortant-missing-photos {
    background-color: rgba(var(--f7-theme-color-grey-rgb), 0.5);
    padding: 10px 20px 10px 20px;
    border-radius: 3px;
    text-align: center;
    font-style: italic;
    color: var(--f7-theme-color-very-dark-grey);
  }

  @media screen and (max-width: 768px) {
    .no-margin-bottom-medium {
      margin-bottom: 0;
      .list {
        margin-bottom: 0;
      }
    }
    .no-margin-top-medium {
      margin-top: 0;
      .list {
        margin-top: 0;
      }
    }
  }
</style>
