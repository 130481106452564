const state = {
  error: null,
  recentErrors: [],
  timingInterval: null,
  isReloading: false,
  reloadTimeout: null
};

// mutations
const mutations = {
  addRecentError(state, payload) {
    state.recentErrors.push(payload);
  },
  removeRecentErrorAtIndex(state, index) {
    state.recentErrors.splice(index, 1);
  },
  setCurrentError(state, payload) {
    state.error = payload;
  },
  setTimingInterval(state, payload) {
    state.timingInterval = payload;
  },
  clearTimingInterval(state) {
    clearInterval(state.timingInterval);
    state.timingInterval = null;
  },
  setIsReloading(state, payload) {
    state.isReloading = payload;
  },
  setReloadTimeout(state, payload) {
    state.reloadTimeout = payload;
  },
  clearReloadTimeout(state) {
    clearTimeout(state.reloadTimeout);
    state.reloadTimeout = null;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
