import Dexie from 'dexie';
//import Pica from 'pica';
import lodash from 'lodash';
import deepdash from 'deepdash-es';
import Pica from "pica";

//https://deepdash.io/
const _ = deepdash(lodash);

export const extractPhotosFromEdl = async function (edl, localEdlId) {
  let db = new PhotoDatabase();
  let promises = [];
  edl = _.eachDeep(edl, (value, key, parentValue, context) => {
    if(context.parent !== undefined
      && key === 'photo'
      && context.parent.key === 'rate'
    ) {
      if(value === null) {
        console.error('missing photo value @', context.path);
        promises.push(new Promise((resolve) => {
          resolve({
            path: context.path,
            delete: true
          });
        }));
      } else {
        let data = value._data !== undefined ? value._data : null;
        let ref = value._ref !== undefined ? value._ref : null;
        let photoContext = value._context !== undefined ? value._context : null;
        if (data && ref) {
          promises.push(new Promise((resolve, reject) => {
            let type = data.split(';')[0].split(':')[1];
            resizeBase64(data, 800, 800, type).then((resizedData) => {
              db.addPhoto(ref, localEdlId, photoContext, resizedData)
                .then((id) => {
                  resolve({
                    path: context.path,
                    id
                  });
                })
                .catch(() => {
                  db.getPhotoByRefAndLocalEdlId(ref, localEdlId)
                    .then((existing) => {
                      resolve({
                        path: context.path,
                        id: existing.id
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                      reject(error);
                    });
                });
            }).catch((error) => {
              reject(error);
            });
          }));
        } else { //Source photo is not exploitable (missing or data corrupted)
          console.error('missing data or ref @', context.path, {data, ref});
          promises.push(new Promise((resolve) => {
            resolve({
              path: context.path,
              delete: true
            });
          }));
        }
      }
    }
  });

  return Promise.all(promises).then((values) => {
    let photoIdPathsToRemove = [];
    _.each(values, (value) => {
      if(value.delete === true) {
        //Get paths to photos that should be removed
        photoIdPathsToRemove.push(getPhotoIdPath(edl, value.path));
      } else {
        _.set(edl, value.path + '._data', '#IndexedDb:' + value.id);
      }
    });
    //Set number of missing photos to delete on edl to warn user
    _.set(edl, 'missingPhotosOnDuplicationCount', photoIdPathsToRemove.length);
    //Remove all missing photos
    edl = removePhotosByIds(edl, photoIdPathsToRemove);
    return edl;
  }).catch((error) => {
    console.error('Une erreur est survenue lors de l\'extraction des photos et de la suppression des signatures', error);
    throw error;
  });
};

export const removeSignaturesFromEdl = async function (edl) {
  return _.eachDeep(edl, (value, key, parentValue) => {
    if (key === 'signatures') {
      parentValue.signatures = null;
    }
  });
};

export const injectPhotosIntoEdl = async function (edl) {
  let db = new PhotoDatabase();
  let promises = [];
  edl = _.eachDeep(edl, (value, key, parentValue, context) => {
    if(context.parent !== undefined
      && (
        (key === 'photo' && context.parent.key === 'rate')
        || context.parent.key === 'signatures'
      )
    ) {
      if(value === null) {
        console.error('missing photo value, ignoring');
      } else {
        let data = value._data !== undefined ? value._data : null;
        let ref = value._ref !== undefined ? value._ref : null;
        if (data && ref) {
          promises.push(new Promise((resolve) => {
            let indexedDbId = +_.trimStart(data, '#IndexedDb:');
            db.getPhoto(indexedDbId)
              .then((photo) => {
                resolve({
                  path: context.path,
                  data: photo.data
                });
              })
              .catch(() => {
                db.getPhotoByRefAndLocalEdlId(ref, edl.localId)
                  .then((existing) => {
                    if(existing === undefined) {
                      resolve({
                        path: context.path,
                        data: null
                      }); //Don't reject, to not block sync, we accept having some photos missing
                    } else {
                      resolve({
                        path: context.path,
                        data: existing.data  //undefined is not an object (evaluating t.data)
                      });
                    }
                  })
                  .catch((error) => {
                    console.error('Photo missing for ref', ref, 'and localId', edl.localId, ' => skipped to not block sync. Error:', error);
                    resolve({
                      path: context.path,
                      data: null
                    }); //Don't reject, to not block sync, we accept having some photos missing
                  });
              });
          }));
        } else {
          console.error('missing data or ref @', context.path, {data, ref});
        }
      }
    }
  });

  return Promise.all(promises).then((values) => {
    _.each(values, (value) => {
        _.set(edl, value.path + '._data', value.data);
    });
    return edl;
  }).catch((error) => {
    console.error('Une erreur est survenue lors de l\'injection des photos dans l\'EDL avant synchronization', error);
    throw error;
  });
}

export const duplicatePhotosFromEdl = async function (edl, duplicateFromLocalEdlId, localEdlId) {
  let db = new PhotoDatabase();
  let promises = [];
  edl = _.eachDeep(edl, (value, key, parentValue, context) => {
    if(context.parent !== undefined
      && key === 'photo'
      && context.parent.key === 'rate'
    ) {
      if(value === null) {
        console.error('missing photo value, ignoring');
      } else {
        let data = value._data !== undefined ? value._data : null;
        let ref = value._ref !== undefined ? value._ref : null;
        let photoContext = value._context !== undefined ? value._context : null;
        if (data && ref) {
          promises.push(new Promise((resolve, reject) => {
            db.getPhotoByRefAndLocalEdlId(ref, duplicateFromLocalEdlId)
              .then((existing) => {
                db.addPhoto(ref, localEdlId, photoContext, existing.data)
                  .then((id) => {
                    resolve({
                      path: context.path,
                      id
                    });
                  })
                  .catch((error) => {
                    console.error(error);
                    reject(error);
                  });
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          }));
        } else {
          console.error('missing data or ref @', context.path, {data, ref});
        }
      }
    }
  });

  return Promise.all(promises).then((values) => {
    _.each(values, (value) => {
      _.set(edl, value.path+'._data', '#IndexedDb:'+value.id);
    })
    return edl;
  }).catch((error) => {
    console.error('Une erreur est survenue lors de l\'extraction des photos et de la suppression des signatures', error);
    throw error;
  });
};

export const deleteEdlPhotos = async function (edl) {
  let db = new PhotoDatabase();
  let promises = [];
  edl = _.eachDeep(edl, (value, key, parentValue, context) => {
    if(context.parent !== undefined
      && (
        (key === 'photo' && context.parent.key === 'rate')
        || context.parent.key === 'signatures'
      )
    ) {
      if(value !== null) {
        let data = value._data !== undefined ? value._data : null;
        let ref = value._ref !== undefined ? value._ref : null;
        if (data && ref) {
          promises.push(new Promise((resolve, reject) => {
            let indexedDbId = +_.trimStart(value._data, '#IndexedDb:');
            db.deletePhoto(indexedDbId)
              .then(() => {
                resolve({
                  path: context.path
                });
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          }));
        } else {
          console.error('missing data or ref @', context.path, {data, ref});
        }
      }
    }
  });

  return Promise.all(promises).then((values) => {
    _.each(values, (value) => {
      _.set(edl, value.path, null);
    })
    return edl;
  }).catch((error) => {
    console.error('Une erreur est survenue lors de la suppression des photos', error);
    throw error;
  });
}

export const prunePhotos = (existingLocalEdlIds = []) => {
  let db = new PhotoDatabase();
  let prunePhotos = [];
  db.eachPhotos((photo) => {
    if(!existingLocalEdlIds.includes(photo.localEdlId)) {
      prunePhotos.push(photo);
    }
  }).then(() => {
    console.log('Pruning '+prunePhotos.length+' unallocated photos...');
    prunePhotos.forEach((photo) => {
      db.deletePhoto(photo.id)
        .catch((error) => {
          console.error(error);
          throw error;
        });
    });
  });
}

export const convertFileToBase64 = async (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const resizeBase64 = async (data, wantedWidth, wantedHeight, type = null) => new Promise((resolve, reject) => {
  let resizeTargetQuality = 0.7;
  let resizeTargetRatio = wantedWidth/wantedHeight;

  let image = new Image();

  image.onload = function() {
    if(image.width <= wantedWidth && image.height <= wantedHeight) { //If already at correct dimensions, don't resize
      //Make sure it is in the right type, and have the right quality
      if(type) {
        if (image.src.startsWith("data:")) {
          const mimeType = image.src.split(";")[0].slice(5); // Extract MIME type
          if (mimeType !== type) {
            let to = document.createElement("canvas");
            to.width = image.width;
            to.height = image.height;

            const ctx = to.getContext('2d');
            ctx.drawImage(image, 0, 0, image.width, image.height);

            to.toBlob(function (blob) {
              resolve(convertFileToBase64(blob));
            }, type, resizeTargetQuality);
          } else {
            //already correct mime-type
            resolve(data);
          }
        } else {
          reject('image does not start with data');
        }
      } else {
        //not asking to change type, size is correct
        resolve(data);
      }
    } else { //do resize (with sharpen)
      let resizeWidth, resizeHeight;
      let ratio = image.width / image.height;

      if (ratio >= resizeTargetRatio) {
        resizeWidth = (image.width > wantedWidth) ? wantedWidth : image.width;
        resizeHeight = resizeWidth / ratio;
      } else {
        resizeHeight = (image.height > wantedHeight) ? wantedHeight : image.height;
        resizeWidth = resizeHeight * ratio;
      }

      let to = document.createElement("canvas");
      to.width = resizeWidth;
      to.height = resizeHeight;

      //Resize manually, without Pica as there is a bug when resizing with Chrome (> 131) breaking the image into tiles
      let ctx = to.getContext('2d');
      ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);

      //Sharpen using Pica, as this does not have a bug and has the best result
      to.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        let imageToSharpen = new Image();
        imageToSharpen.onload = () => {
          let pica = new Pica();
          pica.resize(imageToSharpen, to, {
            alpha: true,
            unsharpAmount: 160,
            unsharpRadius: 0.8,
            unsharpThreshold: 1
          }).then((canvasImgResized) => {
            pica.toBlob(canvasImgResized, type, resizeTargetQuality).then((blob) => resolve(convertFileToBase64(blob)));
          }).catch((error) => {
            reject(error);
          });
        }
        imageToSharpen.src = url
      }, type, 1);
    }
  }

  image.src = data;
});

//Get list of "ids" to make path to photo (instead of indexes).
//This will be needed by removePhotoByIds, as indexes will change at each removal!
const getPhotoIdPath = (edl, path) => {
  let photoIdPath = [];
  let valuePathElements = path.split('.');
  let gradualPath = [];
  valuePathElements.forEach((pathElement) => {
    gradualPath.push(pathElement);
    let node = _.get(edl, gradualPath.join('.'));
    if(node !== null) {
      if (node.id !== undefined) {
        photoIdPath.push(node.id);
      } else if (node.name === "Photo") {
        //id missing on photo (old bug), add a temporary one to later delete it
        node.id = 'temp' + Math.floor(Math.random() * 100);
        _.set(edl, gradualPath.join('.'), node);
        photoIdPath.push(node.id);
      }
    }
  });
  return photoIdPath;
}

//As ids are immutable, where as indexes will move as you remove photos!
//We need to used ids to know exactly which photo to remove,
//then re-transform into CURRENT indexes to be able to remove them
const removePhotosByIds = (edl, idPaths) => {
  idPaths.forEach((idPath) => {
    if(idPath.length === 3) {
      let photoPathWithIndex = idPath.reduce((pathWithIndex, idNode) => {
        let node = _.get(edl, pathWithIndex);
        let index = _.findIndex(node, (child) => {
          return child.id !== undefined && child.id === idNode;
        });
        pathWithIndex.push(index);
        if (pathWithIndex.length === 3) {
          pathWithIndex.push('entry');
        } else if (pathWithIndex.length === 5) {
          pathWithIndex.push('content');
        }
        return pathWithIndex;
      }, ['data', 'edl']);
      edl = removePhotoByPath(edl, photoPathWithIndex);
    } else {
      console.error('Path to photo is incorrect, an edge case has not been handled correctly');
    }
  });
  return edl;
}

//Using indexes. Warning: indexes move after each removal, use "removePhotosByIds" first.
const removePhotoByPath = (edl, path) => {
  let photoIndex = path.pop();
  let photoArray = _.get(edl, path);
  photoArray.splice(photoIndex, 1);
  edl = _.set(edl, path, photoArray);
  return edl;
}

//Database inherits from the Dexie class to handle all database logic for photos
export class PhotoDatabase extends Dexie {
  constructor() {
    // super constructor Dexie(databaseName)
    super('photos');

    this.version(3).stores({
      photos: '++id,&ref,localEdlId,context,data',
    });

    this.photos = this.table('photos');
  }

  getPhoto(id) {
    return this.photos.get(id);
  }

  eachPhotos(callback) {
    return this.photos.each(callback);
  }

  getPhotoByRefAndLocalEdlId(ref, localEdlId) {
    return this.photos.get({ref: ref+":"+localEdlId});
  }

  getPhotosByLocalEdlId(localEdlId) {
    return this.photos.where("localEdlId").equals(localEdlId).toArray();
  }

  getPhotosWithContextByLocalEdlId(localEdlId) {
    return this.photos.where("localEdlId").equals(localEdlId).and((photo) => photo.context !== null).toArray();
  }

  // Returns a promise that resolves if the addition is successful.
  // .then((id) => ...)
  addPhoto(ref, localEdlId, context, data) {
    return this.photos.add({ref: ref+":"+localEdlId, localEdlId, context, data});
  }

  // Use if needed
  // putPhoto(ref, localEdlId, data) {
  //   return this.photos.put({ref: ref+":"+localEdlId, localEdlId, data});
  // }

  updatePhoto(id, data) {
    return this.photos.update(id,{data});
  }

  // Returns a promise that resolves if the deletion is successful.
  deletePhoto(id) {
    return this.photos.delete(id);
  }
}
