import api from '../../api';
import _ from 'lodash';
import {unobserve} from "@/store/helper";

const EDLS_PER_PAGE = 20;

const state = {
  edlToDuplicate: null,
  remoteEdls: null,
  remoteEdlsPage: 1,
  remoteEdlsSearch: null,
  remoteEdlsPagination: null,
  userGroupProfiles: null
};

const getters = {
  //IF FRONTEND PAGINATION, use this:
  // filteredRemoteEdls: (state) => {
  //   let searchString = state.remoteEdlsSearch;
  //   let filteredRemoteEdls = state.remoteEdls;
  //   let ignoreKeys = ['directoryHash'];
  //   if(searchString && searchString !== "" && searchString.length > 1) {
  //     filteredRemoteEdls = filteredRemoteEdls.filter((edl) => {
  //       let key = _.findKey(edl, (element) => {
  //         if(element === null) {
  //           return false;
  //         } else if(!isNaN(searchString) && _.isNumber(element) && element.toString().includes(searchString)) {//search ids
  //           return true;
  //         } else if(_.isString(element)){
  //           return element.toLowerCase().includes(searchString.toLowerCase());
  //         } else {
  //           return false;
  //         }
  //       });
  //       return key !== undefined && !ignoreKeys.includes(key);
  //     });
  //   }
  //   return filteredRemoteEdls;
  // },
  // paginatedRemoteEdls: (state, getters) => {
  //   return _.chunk(getters.filteredRemoteEdls, EDLS_PER_PAGE)[state.remoteEdlsPage - 1] || null;
  // },
  // remoteEdlsPagination: (state, getters) => {
  //   let edls = getters.filteredRemoteEdls;
  //   if(edls === undefined || edls === null) {
  //     edls = state.remoteEdls;
  //   }
  //   const countEdls = (edls ? edls.length : 0);
  //   return {
  //     count: Math.ceil(countEdls / EDLS_PER_PAGE),
  //     limit: EDLS_PER_PAGE,
  //     page: state.remoteEdlsPage,
  //     records: countEdls
  //   };
  // },
  getRemoteEdlById: (state, getters) => (id) => {
    let remoteEdlKey = getters.getRemoteEdlKeyById(id);
    return remoteEdlKey !== undefined && state.remoteEdls !== null ? state.remoteEdls[remoteEdlKey] : null;
  },
  getRemoteEdlKeyById: (state) => (id) => {
    let remoteEdls = state.remoteEdls;
    return remoteEdls ? remoteEdls.findIndex((edl) => {
      return edl.id === id;
    }): null;
  }
};

// actions
const actions = {
  fetchRemoteEdls({state, commit}, payload) {
    return api.edls({
      p: payload.profileId || null,
      q: state.remoteEdlsSearch,
      l: EDLS_PER_PAGE,
      o: payload.resetPagination ? 0 : (state.remoteEdlsPage - 1) * EDLS_PER_PAGE
    }).then((response) => {
      if(payload.resetPagination) {
        commit('remoteEdlsPage', 1);
      }
      commit('remoteEdls', response.data);
      //Server-side pagination:
      let pagination =
        _.mapValues(
          _.mapKeys(
            _.pickBy(response.headers, (value, header) => {
              return header.includes('pagination');
            }),
            (value, header) => {
              return header.replace('pagination-', '');
            }),
          (value) => {
            return parseInt(value);
          });
      commit('remoteEdlsPagination', pagination);
    });
  },
  fetchRemoteEdlById({dispatch, getters}, id) {
    let alreadyLoaded = getters.getRemoteEdlById(id);

    if(alreadyLoaded) {
      //Make edl pre-available if already loaded, even if we reload it
      dispatch('setEdlToDuplicate', alreadyLoaded);
    }

    return api.edl(id);
  },
  fetchUserGroupProfiles({commit}, id) {
    return api.userGroupProfiles(id).then((response) => {
      commit('userGroupProfiles', response.data);
    });
  },
  deleteRemoteEdl({commit, getters}, id) {
    return api.deleteEdl(id).then(() => {
      let key = getters.getRemoteEdlKeyById(id);
      commit('deleteRemoteEdl', key);
    });
  },
  setEdlToDuplicate({commit}, edl) {
    commit('edlToDuplicate', edl);
  },
  updateEdlComment({commit, getters}, payload) {
    return api.updateEdlComment(payload.id, payload.comment).then((response) => {
      let key = getters.getRemoteEdlKeyById(payload.id);
      let edl = response.data;
      commit('remoteEdl', {key, edl});
    });
  },
  updateEdlShowCosts({commit, getters}, payload) {
    return api.updateEdlShowCosts(payload.id, payload.showCosts).then((response) => {
      let key = getters.getRemoteEdlKeyById(payload.id);
      let edl = response.data;
      commit('remoteEdl', {key, edl});
      return response;
    });
  }
};

// mutations
const mutations = {
  remoteEdls(state, payload) {
    state.remoteEdls = payload;
  },
  edlToDuplicate(state, payload) {
    state.edlToDuplicate = payload;
  },
  remoteEdl(state, {key, edl}) {
    state.remoteEdls[key] = edl;
  },
  deleteRemoteEdl(state, payload) {
    let remoteEdls = unobserve(state.remoteEdls);
    remoteEdls.splice(payload, 1);
    state.remoteEdls = remoteEdls;
  },
  remoteEdlsPage(state, page) {
    state.remoteEdlsPage = page;
  },
  remoteEdlsPagination(state, pagination) {
    state.remoteEdlsPagination = pagination;
  },
  remoteEdlsSearch(state, search) {
    if(search === "") {
      search = null;
    }
    state.remoteEdlsSearch = search;
  },
  userGroupProfiles(state, profiles) {
    state.userGroupProfiles = profiles;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
