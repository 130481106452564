<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top :title="me?.login" />

    <navigation-bottom current="account" />

    <f7-page-content v-if="isAuthenticated">
      <f7-block class="text-align-center">
        <img
          v-if="me.logo"
          :src="me.logo"
          class="logo"
        >
        <f7-icon
          v-else
          f7="person_crop_circle"
          size="80"
        />
        <input
          ref="logoInput"
          class="file-input"
          type="file"
          accept="image/*"
          @change="uploadLogo"
        >
      </f7-block>

      <f7-block-title v-if="selectedProfile">
        Profil '{{ selectedProfile.name }}'
      </f7-block-title>
      <f7-list
        v-if="selectedProfile"
        inset
      >
        <f7-list-item
          v-if="(!me.uniqueProfile || me.profiles.length > 1)"
          title="Changer de profil"
          class="list-action"
          @click="changeProfile"
        >
          <template #media>
            <f7-icon
              f7="arrow_2_squarepath"
            />
          </template>
          <template #after>
            <div v-text="selectedProfile.name" />
          </template>
        </f7-list-item>
        <f7-list-item
          title="Renommer mon profil"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="renameProfile"
        >
          <template #media>
            <f7-icon f7="person_alt_circle" />
          </template>
        </f7-list-item>
        <f7-list-item
          :title="selectedProfile.passcode ? 'Modifier mon code secret' : 'Définir un code secret'"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="newPasscode"
        >
          <template #media>
            <f7-icon
              f7="lock_circle"
            />
          </template>
        </f7-list-item>
        <f7-list-item
          v-if="selectedProfile.passcode"
          title="Supprimer mon code secret"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="deletePasscode"
        >
          <template #media>
            <f7-icon
              f7="lock_circle_fill"
            />
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block-title>Compte '{{ me.login }}'</f7-block-title>
      <f7-list inset>
        <f7-list-item
          header="Mon adresse e-mail"
          :title="me.email"
          after="Modifier"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateEmail"
        >
          <template #media>
            <f7-icon f7="at_circle_fill" />
          </template>
        </f7-list-item>
        <f7-list-item
          header="Nom de ma société"
          :title="me.companyName"
          after="Modifier"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateCompanyName"
        >
          <template #media>
            <f7-icon f7="building2" />
          </template>
        </f7-list-item>
        <f7-list-item
          header="Adresse postale de ma société"
          :title="me.companyAddress"
          after="Modifier"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateCompanyAddress"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-item>
        <f7-list-item
          v-if="me.canEditPasswords"
          title="Modifier mon mot de passe"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="newPassword"
        >
          <template #media>
            <f7-icon f7="lock_fill" />
          </template>
        </f7-list-item>
        <f7-list-item
          title="Modifier mon logo"
          after="Format attendu : 150x150"
          class="list-action"
          :class="{disabled: !isOnline}"
          @click="updateLogo"
        >
          <template #media>
            <f7-icon f7="camera_on_rectangle_fill" />
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block-title>Gestion des EDLs</f7-block-title>
      <f7-list inset>
        <f7-list-item
          :link="f7router.generateUrl({name: 'costingCalibration'})"
          title="Calibrer l'auto-chiffrage"
          class="list-action"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="money_euro_circle" />
          </template>
        </f7-list-item>
        <f7-list-item
          class="list-action"
          :class="{disabled: !isOnline}"
          title="Personnaliser les mentions légales"
          @click="showCustomLegalTextPopup = true"
          link
        >
          <template #media>
            <f7-icon f7="paragraph" />
          </template>
        </f7-list-item>
        <f7-list-item
          v-if="me.canAutoEdl"
          :link="f7router.generateUrl({name: 'autoEdls'})"
          title="Gérer les Auto-EDLs"
          class="list-action"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="wand_stars" />
          </template>
        </f7-list-item>
      </f7-list>
      <f7-block-title>Autre</f7-block-title>
      <f7-list inset>
        <f7-list-item title="Vérifier ma connexion internet">
          <template #media>
            <f7-icon f7="antenna_radiowaves_left_right" />
          </template>
          <template #after>
            <div
              v-if="!isOnline"
              style="color: red"
            >
              Hors ligne
            </div>
            <div
              v-else
              style="color: green"
            >
              En ligne
            </div>
          </template>
        </f7-list-item>
        <f7-list-item @click="me.platformToken ? disconnectFromPlatform() : connectToPlatform()" title="Plateforme DDH">
          <template #media>
            <f7-icon f7="smoke" />
          </template>
          <template #after v-if="me.platformToken">
            <span style="color: green">Connecté</span>
          </template>
          <template #after v-else>
            <span>Connecter</span>
          </template>
        </f7-list-item>
        <f7-list-item title="Stockage sur l'appareil">
          <template #media>
            <f7-icon f7="chart_pie_fill" />
          </template>
          <template #after>
            <span v-if="storageState">IDB: {{storageSizeEstimate}}Mo ({{storageQuotaUsage}}%)</span>
            <span class="margin-left">Vuex: {{vuexSizeEstimate}}Mo</span>
          </template>
        </f7-list-item>
        <f7-list-item title="Version de l'application">
          <template #media>
            <f7-icon f7="number_circle_fill" />
          </template>
          <template #after>
            {{ version }}
          </template>
        </f7-list-item>
        <f7-list-item
          link
          popup-open=".assistance-popup"
          title="Assistance"
        >
          <template #media>
            <f7-icon f7="question_circle_fill" />
          </template>
        </f7-list-item>
      </f7-list>

      <f7-block-title v-if="isAdmin">
        Paramètres administrateur
      </f7-block-title>
      <f7-list
        v-if="isAdmin"
        inset
      >
        <f7-list-item
          :link="isSuperAdmin ? f7router.generateUrl({name: 'users'}) : f7router.generateUrl({name: 'userListGroup', params: {id: me.group.id}})"
          title="Gestion des utilisateurs"
          class="list-action"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="person_fill" />
          </template>
        </f7-list-item>
        <f7-list-item
          v-if="isSuperAdmin || me.group.parent === undefined"
          :link="f7router.generateUrl({name: 'userGroups'})"
          title="Gestion des groupes utilisateurs"
          class="list-action"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="person_3" />
          </template>
        </f7-list-item>
        <f7-list-item
          :link="f7router.generateUrl({name: 'statistics'})"
          title="Statistiques"
          class="list-action"
          :class="{disabled: !isOnline}"
        >
          <template #media>
            <f7-icon f7="graph_square" />
          </template>
        </f7-list-item>
      </f7-list>
      <f7-list inset>
        <f7-list-button
          color="red"
          class="list-action"
          @click="logout"
        >
          <f7-icon f7="power" /> Se déconnecter
        </f7-list-button>
      </f7-list>
    </f7-page-content>

    <f7-popup class="assistance-popup" swipe-to-close>
      <f7-page>
        <f7-navbar title="Besoin d'aide sur DDH ?">
          <f7-nav-right>
            <f7-link popup-close>Fermer</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <f7-block-header>N'hésitez pas à nous contacter directement :</f7-block-header>
        <f7-list inset>
          <f7-list-item
            link
            title="Par téléphone"
          >
            <a href="tel:+33630260219">06 30 26 02 19</a>
          </f7-list-item>
          <f7-list-item
            link
            title="Par e-mail"
          >
            <a href="mailto:support@hamelinsolutions.fr">support@hamelinsolutions.fr</a>
          </f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>

    <custom-legal-text-popup
      :opened="showCustomLegalTextPopup"
      :edl="me?.customLegalText"
      :convention="me?.customLegalTextConvention"
      context="user"
      @update="updateCustomLegalTexts"
      @close="showCustomLegalTextPopup = false"
    />
  </f7-page>
</template>

<script>
import {mapActions, mapState} from "vuex";
import firewall from "../../mixins/firewall";
import NavigationTop from "../../layout/NavigationTop";
import NavigationBottom from "../../layout/NavigationBottom";
import offline from "@/components/mixins/offline";
import {f7} from "framework7-vue";
import logo from "@/components/mixins/logo";
import CustomLegalTextPopup from "@/components/pages/User/CustomLegalTextPopup.vue";

export default {
  name: "UserAccount",
  components: {CustomLegalTextPopup, NavigationTop, NavigationBottom},
  mixins: [firewall, offline, logo],
  props: {
    f7router: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      storageState: null,
      showCustomLegalTextPopup: false
    };
  },
  computed: {
    ...mapState('me', [
      'me',
      'selectedProfile'
    ]),
    version: function () {
      return process.env.VUE_APP_VERSION;
    },
    storageQuotaUsage: function () {
      return this.storageState ? Math.round((this.storageState.usage / this.storageState.quota) * 100)  : null;
    },
    storageSizeEstimate: function () {
      let usage = this.storageState?.usageDetails?.indexedDB || this.storageState?.usage;
      return usage ? (usage / (1024*1024)).toFixed(2) : null; //in Mo
    },
    vuexSizeEstimate: function () {
      return (window.localStorage.vuex.length / (1024*1024)).toFixed(2); //in Mo
    }
  },
  mounted: function () {
    if(this.me === undefined) {
      this.f7router.navigate({name: 'login'});
    }

    if (navigator.storage && navigator.storage.estimate) {
      navigator.storage.estimate().then((estimate) => {
        this.storageState = estimate;
      });
    }
  },
  methods: {
    ...mapActions('security', [
      'resetJwtToken'
    ]),
    ...mapActions('me', [
      'updateMe',
      'updatePassword',
      'updatePasscode',
      'updatePlatformToken',
      'resetSelectedProfile',
      'resetUser',
      'selectProfile',
      'updateProfile'
    ]),
    logout() {
      this.resetJwtToken();
      this.resetSelectedProfile();
      this.resetUser();
      this.f7router.navigate({name: 'login'});
    },
    changeProfile() {
      this.f7router.navigate({name: 'profileSelection'}, {reloadAll: true});
    },
    updateCompanyName() {
      f7.dialog.prompt('Le nom de ma société', 'Modifier',
        (value) => {
          f7.dialog.preloader('Enregistrement...');
          this.updateMe({companyName: value})
            .then(() => f7.dialog.close())
            .catch(() => f7.dialog.alert('Votre nom de société n\'a pas pu être mis à jour'))
        },
        null,
        this.me.companyName
      )
    },
    updateCompanyAddress() {
      f7.dialog.create({
        title: "Modifier",
        text: "L'adresse de ma société",
        cssClass: "dialog-textarea",
        //Content style inline - did not succeed making this css work in component styles
        content: `<div style="margin-top: 10px;">
            <textarea rows="4" style="width: 100%; background-color: white; padding: 10px; border: 1px solid var(--f7-theme-color-grey); border-radius: 3px;">${this.me.companyAddress}</textarea>
        </div>`,
        buttons: [
          {
            text: 'Annuler',
            bold: false,
            close: true
          },
          {
            text: 'Je confirme',
            bold: true,
            close: true
          }
        ],
        onClick: (dialog, index) => {
          const inputValue = dialog.$el.find('.dialog-textarea textarea').val();
          if (index === 1) {
            f7.dialog.preloader('Enregistrement...');
            this.updateMe({companyAddress: inputValue})
              .then(() => f7.dialog.close())
              .catch(() => f7.dialog.alert('L\'adresse de votre société n\'a pas pu être mise à jour'))
          }
        },
      }).open();
    },
    updateEmail() {
      f7.dialog.prompt('Mon adresse e-mail', 'Modifier',
        (value) => {
          f7.dialog.preloader('Enregistrement...');
          this.updateMe({email: value})
            .then(() => f7.dialog.close())
            .catch(() => f7.dialog.alert('Votre adresse e-mail n\'a pas pu être mise à jour'))
        },
        null,
        this.me.email
      )
    },
    renameProfile() {
      f7.dialog.prompt('Mon profil', 'Renommer',
        (value) => {
          f7.dialog.preloader('Enregistrement...');
          this.updateProfile({name: value})
            .then(() => f7.dialog.close())
            .catch(() => f7.dialog.alert('Votre nom de profil n\'a pas pu être mise à jour'))
        },
        null,
        this.selectedProfile.name
      )
    },
    doUploadLogo(payload) { //See logo mixin
      return this.updateMe(payload);
    },
    newPassword() {
      f7.dialog.password('Je définis mon nouveau mot de passe', 'Mot de passe du compte '+this.me.login,
        (password) => {
          f7.dialog.password('Je confirme mon nouveau mot de passe','Mot de passe du compte '+this.me.login,
            (confirmPassword) => {
              if(password !== confirmPassword) {
                f7.dialog.create({
                  title: "Attention",
                  text: "Les deux mots de passe ne correspondent pas, veuillez essayer à nouveau",
                  buttons: [
                    {
                      text: 'OK',
                      bold: true,
                      close: true
                    }
                  ]
                }).open();
              } else {
                f7.dialog.preloader('Enregistrement...');
                this.updatePassword({password})
                  .then(() => {
                    f7.dialog.close();
                    f7.dialog.create({
                      title: null,
                      text: "Votre mot de passe a bien été mis à jour !",
                      buttons: [
                        {
                          text: 'OK',
                          bold: true,
                          close: true
                        }
                      ]
                    }).open();
                  })
                  .catch(() => {
                    f7.dialog.create({
                      title: "Erreur",
                      text: "Votre mot de passe n'a pas pu être mis à jour...veuillez nous contacter",
                      buttons: [
                        {
                          text: 'OK',
                          bold: true,
                          close: true
                        }
                      ]
                    }).open();
                  });
              }
            }
          )
        }
      )
    },
    newPasscode() {
      f7.dialog.create({
        text: 'Je définis un code secret à 4 chiffres pour accéder à mon profil',
        title: 'Code secret du profil '+this.selectedProfile.name,
        content:
          '<div class="dialog-input-field item-input">' +
            '<div class="item-input-wrap">' +
              '<input class="dialog-input text-align-center" type="tel" placeholder="Code à 4 chiffres" required validate pattern="[0-9]{4}" data-error-message=" ">' +
            '</div>' +
          '</div>',
        buttons: [
          {
            text: 'Valider',
            bold: true,
            close: true,
            onClick: (dialog) => {
              let passcode = dialog.$el.find('.dialog-input').val();
              if(!this.verifyPasscodeFormat(passcode)) {
                f7.dialog.create({
                  title: "Attention",
                  text: "Le code secret doit faire 4 chiffres",
                  buttons: [
                    {
                      text: 'OK',
                      bold: true,
                      close: true
                    }
                  ]
                }).open();
              } else {
                f7.dialog.create({
                  text: 'Je confirme mon code secret',
                  title: 'Code secret du profil '+this.selectedProfile.name,
                  content:
                    '<div class="dialog-input-field item-input">' +
                    '<div class="item-input-wrap">' +
                    '<input class="dialog-input text-align-center" type="tel" placeholder="Code à 4 chiffres" required validate pattern="[0-9]{4}" data-error-message=" ">' +
                    '</div>' +
                    '</div>',
                  buttons: [
                    {
                      text: 'Valider',
                      bold: true,
                      close: true,
                      onClick: (dialog) => {
                        let confirmPasscode = dialog.$el.find('.dialog-input').val();
                        if (passcode !== confirmPasscode) {
                          f7.dialog.create({
                            title: "Attention",
                            text: "Les deux codes secrets ne correspondent pas, veuillez essayer à nouveau",
                            buttons: [
                              {
                                text: 'OK',
                                bold: true,
                                close: true
                              }
                            ]
                          }).open();
                        } else {
                          f7.dialog.preloader('Enregistrement...');
                          this.updatePasscode({passcode})
                            .then(() => {
                              f7.dialog.close();
                              f7.dialog.create({
                                title: null,
                                text: "Votre code secret est à jour !",
                                buttons: [
                                  {
                                    text: 'OK',
                                    bold: true,
                                    close: true
                                  }
                                ]
                              }).open();
                            })
                            .catch(() => {
                              f7.dialog.create({
                                title: "Erreur",
                                text: "Votre code secret n'a pas pu être mis à jour...veuillez nous contacter",
                                buttons: [
                                  {
                                    text: 'OK',
                                    bold: true,
                                    close: true
                                  }
                                ]
                              }).open();
                            });
                        }
                      }
                    }
                  ]
                }).open();
              }
            }
          }
        ]
      }).open();
    },
    deletePasscode() {
      f7.dialog.confirm('Êtes-vous certain de vouloir supprimer votre code secret ?', 'Confirmer', () => {
        this.updatePasscode({passcode: null})
          .then(() => {
            f7.dialog.close();
            f7.dialog.create({
              title: null,
              text: "Votre code secret a été supprimé !",
              buttons: [
                {
                  text: 'OK',
                  bold: true,
                  close: true
                }
              ]
            }).open();
          })
          .catch(() => {
            f7.dialog.create({
              title: "Erreur",
              text: "Votre code secret n'a pas pu être supprimé...veuillez nous contacter",
              buttons: [
                {
                  text: 'OK',
                  bold: true,
                  close: true
                }
              ]
            }).open();
          });
      });
    },
    verifyPasscodeFormat(passcode) {
      return passcode.match(/^[0-9]{4}$/g);
    },
    updateCustomLegalTexts(payload) {
      f7.dialog.preloader('Enregistrement des mentions légales...');
      this.updateMe({
        customLegalText: payload.edl,
        customLegalTextConvention: payload.convention
      }).then(() => {
        f7.dialog.close();
        f7.dialog.create({
          title: null,
          text: "<strong>Vos mentions légales ont bien été mises à jour.</strong><br/><br/>Elles figureront sur vos nouveaux PDFs,<br/>ou en re-générant les PDFs des EDLs existants.",
          buttons: [
            {
              text: 'OK',
              bold: true,
              close: true
            }
          ]
        }).open();
      })
    },
    connectToPlatform() {
      f7.dialog.password('Entrez la clé de connexion présentée dans la section Mon Profil sur la plateforme moncompte.etatdeslieuxddh.fr', 'Connexion plateforme DDH',
        (password) => {
          f7.dialog.preloader('Enregistrement...');
          this.updatePlatformToken({platformToken: password})
            .then(() => {
              f7.dialog.close();
              f7.dialog.create({
                title: null,
                text: "Vous êtes désormais connectés à la plateforme DDH",
                buttons: [
                  {
                    text: 'OK',
                    bold: true,
                    close: true
                  }
                ]
              }).open();
            })
            .catch((error) => {
              const statusCode = error.response.status;
              f7.dialog.close();
              f7.dialog.create({
                title: "Erreur",
                text: statusCode === 407
                  ? "Cette clé ne correspond pas à une clé de la plateforme"
                  : "Une erreur est survenue lors de la connexion...veuillez nous contacter",
                buttons: [
                  {
                    text: 'OK',
                    bold: true,
                    close: true
                  }
                ]
              }).open();
            });
        }
      )
    },
    disconnectFromPlatform() {
      f7.dialog.create({
        text: "Êtes-vous certains de vouloir déconnecter votre compte de la plateforme DDH ?",
        title: "Déconnexion plateforme DDH",
        buttons: [
          {
            text: 'Non',
            bold: false,
            close: true
          },
          {
            text: 'Oui',
            bold: true,
            close: true,
            onClick: () => {
              this.updatePlatformToken({platformToken: null})
                .then((response) => {
                  console.log(response);
                  f7.dialog.close();
                  f7.dialog.create({
                    title: null,
                    text: "Vous êtes désormais déconnectés de la plateforme DDH. Recliquez pour vous connecter à nouveau.",
                    buttons: [
                      {
                        text: 'OK',
                        bold: true,
                        close: true
                      }
                    ]
                  }).open();
                })
                .catch(() => {
                  f7.dialog.create({
                    title: "Erreur",
                    text: "Une erreur est survenue lors de la déconnexion... veuillez nous contacter",
                    buttons: [
                      {
                        text: 'OK',
                        bold: true,
                        close: true
                      }
                    ]
                  }).open();
                });
            }
          }
        ]
      }).open();
    }
  }
}
</script>

<style lang="scss" scoped>
  .logo {
    height: 150px;
    width: 150px;
  }
  .file-input {
    display: none;
  }
  .list ul li.list-action:hover {
    cursor: pointer;
  }
  .assistance {
    display: inline-block;
    margin-left: 20px;
  }
  @media screen and (max-width: 550px) {
    .assistance {
      display: block;
      margin-left: 0;
    }
  }
</style>
