<template>
  <f7-popup
    class="auto-edl-select-popup"
    :opened="opened"
    @popup:close="$emit('close')"
    @popup:closed="fixPopupCloseOnSafari"
    @popup:opened="adjustLayout"
  >
    <f7-searchbar
      :value="remoteEdlsSearch"
      custom-search
      placeholder="Rechercher un EDL"
      @searchbar:search="onSearch"
    />
    <f7-list>
      <f7-list-item
        v-for="edl in remoteEdls"
        :key="edl.id"
        :value="edl.id"
        :title="getEdlName(edl)"
        @click="selectEdl(edl)"
      >
        <template #after>
          <i v-if="edl.id === selected" class="f7-icons color-theme">checkmark_alt</i>
        </template>
      </f7-list-item>
    </f7-list>
  </f7-popup>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {f7} from "framework7-vue";

export default {
  name: "AutoEdlSelectPopup",
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Number,
      default: null
    }
  },
  emits: ['select', 'close'],
  computed: {
    ...mapState('edlTemp', [
      'remoteEdls',
      'remoteEdlsSearch'
    ]),
    ...mapState('me', [
      'selectedProfile'
    ]),
  },
  watch: {
    remoteEdlsSearch: {
      handler: 'loadEdls',
    }
  },
  created() {
    window.addEventListener("resize", this.adjustLayout);
  },
  unmounted() {
    window.removeEventListener("resize", this.adjustLayout);
  },
  mounted: function() {
    this.$nextTick(() => {
      this.loadEdls();
    });
  },
  methods: {
    ...mapActions('edlTemp', [
      'fetchRemoteEdls',
    ]),
    ...mapMutations('edlTemp', {
      'updateRemoteEdlsSearch': 'remoteEdlsSearch',
    }),
    loadEdls() {
      f7.preloader.show();
      this.pagePreloaderRunning = true;
      this.fetchRemoteEdls({
        profileId: this.selectedProfile.id
      }).then(() => {
        f7.preloader.hide();
      });
    },
    getEdlName: function (edl) {
      let name = '#'+ edl.id + ' [' + edl.edlIO + '] ' + edl.agent + ' - ' + edl.renter;
      if(edl.housingRef) {
        name += ' ('+edl.housingRef+')';
      }
      return name;
    },
    onSearch: function (searchbar, query) {
      this.updateRemoteEdlsSearch(query);
    },
    selectEdl: function (edl) {
      this.$emit('select', {
        value: edl.id,
        label: this.getEdlName(edl)
      });
      this.$emit('close');
    },
    adjustLayout: function () {
      let alwaysShow = this.$refs.alwaysShow;
      let popup = this.$refs.popup;
      let navbar = this.$refs.navbar;

      this.scrollUnderStyle =
        (alwaysShow !== undefined && popup !== undefined && navbar !== undefined) ? {
          height: (popup.$el.clientHeight - navbar.$el.clientHeight - alwaysShow.clientHeight - 10) + 'px'
        } : null;
    },
    fixPopupCloseOnSafari: function () {
      //Patch to fix Safari issue, popup still rendered on screen after close until resize
      window.dispatchEvent(new Event('resize'));
    }
  }
}
</script>


<style scoped lang="scss">

</style>
