<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      title="Nouvel Auto-EDL"
      back="autoEdls"
    />

    <navigation-bottom current="account" />

    <f7-page-content>
      <f7-list no-hairlines-md>
        <f7-list-item
          link
          header="EDL à dupliquer"
          :title="selectedEdlLabel ?? 'Cliquer pour choisir'"
          @click="openSelectPopup = true"
        >
          <template #media>
            <f7-icon f7="doc_on_doc" />
          </template>
        </f7-list-item>
        <f7-list-input
          :value="autoEdl.email"
          label="E-mail du locataire"
          type="email"
          placeholder="tony@stark-industries.com"
          required
          validate
          validate-on-blur
          clear-button
          @input="autoEdl.email = $event.target.value"
        >
          <template #media>
            <f7-icon f7="envelope" />
          </template>
        </f7-list-input>
        <f7-list-input
          :value="autoEdl.preData.etage"
          label="Étage / Porte"
          type="text"
          placeholder="3e étage, porte 2"
          required
          validate
          validate-on-blur
          clear-button
          @input="autoEdl.preData.etage = $event.target.value"
        >
          <template #media>
            <f7-icon f7="building_2_fill" />
          </template>
        </f7-list-input>

        <f7-list-input
          :value="autoEdl.preData.numAppart"
          label="Référence / Numéro d'appartement"
          type="text"
          placeholder="2046"
          required
          validate
          validate-on-blur
          clear-button
          @input="autoEdl.preData.numAppart = $event.target.value"
        >
          <template #media>
            <f7-icon f7="number_circle" />
          </template>
        </f7-list-input>

        <f7-list inset>
          <f7-list-button
            title="Créer & Envoyer email"
            @click="submit"
          />
        </f7-list>
      </f7-list>
      <auto-edl-select-popup
        :opened="openSelectPopup"
        :selected="autoEdl.duplicateFromId"
        @close="openSelectPopup = false"
        @select="selectEdlToDuplicate"
      />
    </f7-page-content>
  </f7-page>
</template>

<script>
  import firewall from "../../../mixins/firewall";
  import {mapActions} from "vuex";
  import NavigationTop from "../../../layout/NavigationTop.vue";
  import NavigationBottom from "../../../layout/NavigationBottom.vue";
  import { f7 } from "framework7-vue";
  import {nextTick} from "vue";
  import AutoEdlSelectPopup from "@/components/pages/User/AutoEdlManagement/AutoEdlSelect.vue";

  export default {
    name: "AutoEdlAdd",
    components: {AutoEdlSelectPopup, NavigationBottom, NavigationTop},
    mixins: [firewall],
    props: {
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function() {
      return {
        autoEdl: {
          duplicateFromId: null,
          email: null,
          preData: {
            etage: null,
            numAppart: null
          }
        },
        openSelectPopup: false,
        selectedEdlLabel: null
      }
    },
    computed: {
      formIsValid() {
        return this.autoEdl.email && this.autoEdl.duplicateFromId;
      }
    },
    mounted: function () {
      nextTick(() => {
        this.limitToCanAutoEdls();
      });
    },
    methods: {
      ...mapActions('autoEdl', [
        'createAutoEdl',
      ]),
      selectEdlToDuplicate({value, label}) {
        this.autoEdl.duplicateFromId = value;
        this.selectedEdlLabel = label;
      },
      submit() {
        if (!this.formIsValid) {
          f7.dialog.alert('Veuillez corriger les informations renseignées !', 'Erreur')
          return
        }

        f7.preloader.show('Enregistrement en cours...');

        let data = this.autoEdl;
        data.profileId = this.selectedProfile.id;
        this.createAutoEdl(data).then(() => {
          f7.preloader.hide();
          this.f7router.navigate({name: 'autoEdls'});
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
